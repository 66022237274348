import './PatientForm.css';
import React, { useState } from 'react';
import { FaSave } from "react-icons/fa";
import { TableCell } from '@mui/material';
import { TextField } from '@mui/material';

import Tooltip from '@mui/material/Tooltip';
import ApiManager from '../../ApiManager/ApiManager';


export default function PatientForm({ patient, patients, setPatients }) {

    const [patientName, setPatientName] = useState(patient.name);
    const [patientTelephone, setPatientTelephone] = useState(patient.telephone);
    const [patientNif, setPatientNif] = useState(patient.nif);
    const [patientEmail, setPatientEmail] = useState(patient.email);
    const [patientBirthYear, setPatientBirthYear] = useState(patient.birth_year);
    const [patientOccupation, setPatientOccupation] = useState(patient.occupation);
    const [patientAllergies, setPatientAllergies] = useState(patient.allergies);
    const [patientComments, setPatientComments] = useState(patient.comments);


    const handleSavePatient = (e) => {
        e.preventDefault();
        const data = {
            name: patientName,
            telephone: patientTelephone,
            nif: patientNif,
            email: patientEmail,
            birth_year: patientBirthYear,
            occupation: patientOccupation,
            allergies: patientAllergies,
            comments: patientComments
        }
        ApiManager.modifyPatient(patient.id, data)
            .then((response) => {


                // Find the index of the updated professional
                const updatedIndex = patients.findIndex(p => p.id === patient.id);

                // Create a new array with the updated professional at the correct index
                const updatedPatients = [...patients];
                updatedPatients[updatedIndex] = response;

                // Update the state with the new array
                setPatients(updatedPatients);


            }).catch((error) => {
                console.error(error)
            })
    }


    return (
        <TableCell colSpan={6}>
            <form className="table-row-expanded"  id='patient-form' onSubmit={(e) => handleSavePatient(e)}>

                <h3>Datos de contacto</h3>
                <div className='patient-form-group' id='patient-form-group-contact'>
                    
                    <TextField className='patient-form-edit-input' margin="dense" name="name" type='text'  label='Nombre' fullWidth defaultValue={patientName} onChange={(e) => setPatientName(e.target.value)}  InputLabelProps={{shrink: true}}/>
                    <TextField className='patient-form-edit-input' margin="dense" name="nif" type='text'  label='NIF/CIF' fullWidth defaultValue={patientNif} onChange={(e) => setPatientNif(e.target.value)}  InputLabelProps={{shrink: true}}/>
                    <TextField className='patient-form-edit-input' margin="dense" name="telephone" type='text'  label='Teléfono' fullWidth defaultValue={patientTelephone} onChange={(e) => setPatientTelephone(e.target.value)}  InputLabelProps={{shrink: true}}/>
                    <TextField className='patient-form-edit-input' margin="dense" name="email" type='text'  label='Email' fullWidth defaultValue={patientEmail} onChange={(e) => setPatientEmail(e.target.value)}  InputLabelProps={{shrink: true}}/>
                </div>

                <h3>Datos clínicos</h3>
                <div className='patient-form-group' id='patient-form-group-contact'>
                <TextField className='patient-form-edit-input' margin="dense" name="name" type='number' label='Año nacimiento' fullWidth defaultValue={patientBirthYear} onChange={(e) => setPatientBirthYear(e.target.value)}  InputLabelProps={{shrink: true}}/>
                    <TextField className='patient-form-edit-input' margin="dense" name="name" type='text' label='Ocupación' fullWidth defaultValue={patientOccupation} onChange={(e) => setPatientOccupation(e.target.value)}  InputLabelProps={{shrink: true}}/>
                    <TextField className='patient-form-edit-input' margin="dense" name="name" type='text' label='Alergias' fullWidth defaultValue={patientAllergies} onChange={(e) => setPatientAllergies(e.target.value)}  InputLabelProps={{shrink: true}}/>
                </div>

                <h3>Comentarios</h3>
                <div className='patient-form-group' id='patient-form-group-comments'>
                    <TextField multiline rows={4} className='patient-form-edit-input' margin="dense" name="name" type='text' label='Comentarios' fullWidth defaultValue={patientComments} onChange={(e) => setPatientComments(e.target.value)}  InputLabelProps={{shrink: true}}/>
                </div>


                <div id='patient-form-actions'>
                    <Tooltip arrow title="Guardar"><button className='reservation-form-button' id='patient-save-button' onClick={(e) => handleSavePatient(e)}><FaSave /></button></Tooltip>
                </div>
            </form>
        </TableCell>
    );
};