import './Schedule.css';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/es'; 
import { FaRegTrashCan } from "react-icons/fa6";
import ApiManager from '../../ApiManager/ApiManager';
import Loader from '../Loader/Loader';
import { TableCell } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { FaSave } from "react-icons/fa";

const day_name = {
    0: 'Lunes',
    1: 'Martes',
    2: 'Miércoles',
    3: 'Jueves',
    4: 'Viernes',
    5: 'Sábado',
    6: 'Domingo',
}

export default function Schedule({ professional }) {
    moment.locale('es');

    const [, setSchedules] = useState(null);
    const [schedule, setSchedule] = useState();
    const [newTime, setNewTime] = useState(null);
    const [loading, setLoading] = useState(false);
    const [saved, setSaved] = useState(true);
    const [creatingAutomaticSlots, setCreatingAutomaticSlots] = useState(false);

    useEffect(() => {
        ApiManager.getSchedules()
            .then((response) => {
                setSchedules(response);
                setSchedule(JSON.parse(response.filter((s) => s.user_id === professional.id)[0].schedule));
            })
            .catch((error) => {
                setSchedule(null);
            });
    }, [professional.id]);

    const handleDeleteSlot = (day, time) => {
        const filteredSchedule = {
            ...schedule,
            [day]: schedule[day].filter(h => h !== time)
        };
        setSchedule(filteredSchedule);
        setSaved(false);
    }

    const handleAddSlot = (day) => {
        if (!newTime) return;
        const extendedSchedule = {
            ...schedule,
            [day]: schedule[day] ? [...schedule[day], newTime] : [newTime]
        };
        extendedSchedule[day] = [...new Set(extendedSchedule[day])];
        setSchedule(extendedSchedule);
        setSaved(false);
    }

    const handleSaveSchedule = () => {
        setLoading(true);
        const data = { schedule: JSON.stringify(schedule) };
        ApiManager.putSchedule(professional.id, data)
            .then((response) => {
                setSchedule(JSON.parse(response.schedule));
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setLoading(false);
                setSaved(true);
            });
    }

    const handleCreateAutomaticSlotsCLick = () => {
        setCreatingAutomaticSlots(true);
        ApiManager.createAutomaticSlots()
            .then((response) => {
                // console.log(response)
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setCreatingAutomaticSlots(false);
            });
    }

    const handleNewSchedule = () => {
        setLoading(true);
        ApiManager.createDefaultSchedule({ 'user_id': professional.id })
            .then((response) => {
                setSchedule(JSON.parse(response.schedule));
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        const sortScheduleTimes = () => {
            const sortedSchedule = { ...schedule };
            for (const day in sortedSchedule) {
                sortedSchedule[day] = sortedSchedule[day].sort((a, b) => a.localeCompare(b));
            }
            return sortedSchedule;
        };

        if (schedule) {
            const sortedSchedule = sortScheduleTimes();
            setSchedule(sortedSchedule);
        }
    }, [schedule]);

    return (
        <TableCell colSpan={4}>
            <div id="schedule-wrapper">
                <div id="schedule-page-banner">
                    <h2 id="schedules-banner-title">Horario de <span className='patient-name'>{professional.name}</span></h2>

                    { !saved && 
                        <Tooltip arrow title="Guardar">
                            <button id='schedule-save-button' onClick={() => handleSaveSchedule()} disabled={saved}>
                                {loading ? 
                                    <Loader /> 
                                    : 
                                    <FaSave />
                                }
                            </button>
                        </Tooltip>
                    }

                    {saved &&
                        <button id='staff-create-slots-btn' onClick={() => handleCreateAutomaticSlotsCLick()}>
                            {creatingAutomaticSlots ? 
                            <Loader />
                            :
                            'Crear huecos'}
                        </button>
                    }
                </div>

                <div id='schedule-content'>
                    {schedule ?
                        Object.keys(schedule).map((day, index) => (
                            <div className="schedule-day" key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <h4 className='schedule-day-title'>{day_name[day]}</h4>
                                <div className='schedule-day-slots'>
                                    {schedule && schedule[day].map((t, i) => (
                                        <div className='schedule-slot' key={i}>
                                            {moment(t, 'HH:mm').format('HH:mm')}
                                            <FaRegTrashCan className='trash-icon' onClick={() => handleDeleteSlot(day, t)} />
                                        </div>
                                    ))}
                                </div>
                                <div className='schedule-new-slot-form'>
                                    <input className='schedule-new-slot-input' type='time' onChange={(e) => setNewTime(e.target.value)}></input>
                                    <button className='schedule-new-slot-button' onClick={() => handleAddSlot(day)}>Añadir</button>
                                </div>
                            </div>
                        ))
                        :
                        <button id='new-schedule-button' onClick={() => handleNewSchedule()}>
                            {loading ? 
                                <Loader /> 
                                : 
                                'Crear horario' 
                            }
                        </button>
                    }
                </div>
            </div>
        </TableCell>
    );
}
