import './PatientDirectory.css'
import React, { useState } from 'react';
import {TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, TextField, InputAdornment, IconButton } from '@mui/material';
import {  } from '@mui/material';
import { MdEmail } from "react-icons/md";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { BsTelephoneForward } from "react-icons/bs";
import { HiIdentification } from "react-icons/hi2";
import { MdOutlineHistory } from "react-icons/md";
import { FaCircleInfo } from "react-icons/fa6";
import Tooltip from '@mui/material/Tooltip';


import ApiManager from '../../ApiManager/ApiManager';
import moment from 'moment';
import 'moment/locale/es'; 

import PatientHistory from '../PatientHistory/PatientHistory';
import PatientForm from '../PatientForm/PatientForm';


export default function PatientDirectory() {

    moment.locale('es');

    const [patients, setPatients] = useState([]);
    const [expandedRow, setExpandedRow] = useState(null);
    const [action, setAction] = useState(null);
    const [patientHistory, setPatientHistory] = useState([]);
    const [searchPatient, setSearchPatient] = useState(null);
    const [searchNIF, setSearchNIF] = useState(null);
    const [searchTelephone, setSearchTelephone] = useState(null);


    const handleEditClick = (index) => {
        setExpandedRow(index === expandedRow ? null : index);
        setAction('edit')
    };



    const handleHistoryClick = (index, patient_id, telephone) => {
        setExpandedRow(index === expandedRow ? null : index);
        setAction('history')
        ApiManager
            .getPatientHistory(patient_id)
            .then((response) => {
                setPatientHistory(response)
            })
            .catch((error) => {
                console.error(error)
            })
    };


    const handleSearchPatientByTelephone = (e) => {
        e.preventDefault();

        if (searchTelephone !== '') {
            ApiManager.getPatientsByTelephone(searchTelephone)
                .then((res) => {
                    setPatients(res)
                })
                .catch((error) => {
                    console.error(error)
                })
        }
    }

    

    const handleSearchPatientByNIF = (e) => {
        e.preventDefault();

        if (searchNIF !== '') {
            ApiManager.getPatientsByNIF(searchNIF)
                .then((res) => {
                    setPatients(res)
                })
                .catch((error) => {
                    console.error(error)
                })
        }
    }


    const handleSearchPatientByName = (e) => {
        e.preventDefault();

        if (searchPatient !== '') {
            ApiManager.getPatientsByName(searchPatient)
                .then((res) => {
                    setPatients(res)
                })
                .catch((error) => {
                    console.error(error)
                })
        }
    }

    return (
        <>
        <div id="patient-directory-wrapper">
            <div id="patient-directory-page-banner">
                <h1 id="patient-directory-banner-title">Pacientes</h1>
            </div>

            <div id="patients-directory-search-wrapper">
                <form  className='patients-directory-search-block' onSubmit={(e) => handleSearchPatientByTelephone(e)}>
                    <TextField
                        size="small"
                        margin="dense"
                        name="patient-telephone"
                        label="Telefóno"
                        value={searchTelephone || ''}
                        onChange={(e) => setSearchTelephone(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={(e) => handleSearchPatientByTelephone(e)}>
                                        <FaMagnifyingGlass id='patient-directory-search-icon' />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        InputLabelProps={{ shrink: true }}
                    />
                </form>
                <form className='patients-directory-search-block' onSubmit={(e) => handleSearchPatientByNIF(e)}>
                    <TextField
                        size="small"
                        margin="dense"
                        name="patient-nif"
                        label="NIF"
                        value={searchNIF || ''}
                        onChange={(e) => setSearchNIF(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={(e) => handleSearchPatientByNIF(e)}>
                                        <FaMagnifyingGlass id='patient-directory-search-icon' />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        InputLabelProps={{ shrink: true }}
                    />
                </form>
                <form className='patients-directory-search-block' onSubmit={(e) => handleSearchPatientByName(e)}>
                    <TextField
                        size="small"
                        margin="dense"
                        name="patient-name"
                        label="Nombre"
                        value={searchPatient || ''}
                        onChange={(e) => setSearchPatient(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={(e) => handleSearchPatientByName(e)}>
                                        <FaMagnifyingGlass id='patient-directory-search-icon' />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        InputLabelProps={{ shrink: true }}
                    />
                </form>
            </div>

            <div id='patient-directory-table'>
                <TableContainer component={Paper}>
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell><b>Nombre</b></TableCell>
                                <TableCell><b>Edad</b></TableCell>
                                <TableCell align="left"><b><HiIdentification /> &nbsp; NIF</b></TableCell>
                                <TableCell align="left"><b><BsTelephoneForward /> &nbsp; Teléfono</b></TableCell>
                                <TableCell align="left"><b><MdEmail /> &nbsp; Email</b></TableCell>
                                <TableCell align="center"><b>Acciones</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {patients && patients.map((p, index) => (
                            <React.Fragment key={p.id}>
                                <TableRow className="table-row" sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                    <TableCell component="th" scope="row">
                                        {p.name}
                                    </TableCell>
                                    <TableCell align="left">{p.birth_year && moment().year() - p.birth_year}</TableCell>
                                    <TableCell align="left">{p.nif}</TableCell>
                                    <TableCell align="left">{p.telephone}</TableCell>
                                    <TableCell align="left">{p.email}</TableCell>
                                    <TableCell className='table-actions-cell'>
                                        <Tooltip arrow title="Información"><button className='patient-directory-button' onClick={() => handleEditClick(index)}><FaCircleInfo /></button></Tooltip>
                                        <Tooltip arrow title="Historial"><button className='patient-directory-button' onClick={() => handleHistoryClick(index, p.id, p.telephone)}><MdOutlineHistory /></button></Tooltip>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    {expandedRow === index &&  (
                                        action === 'history' ?
                                            <PatientHistory patient={p} history={patientHistory} />
                                            :
                                            <PatientForm patient={p} patients={patients} setPatients={setPatients} />
                                    )}
                                </TableRow>
                            </React.Fragment>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
        </>
    );
}