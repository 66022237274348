
import './Footer.css'
import React from 'react';
import { FaInstagram } from "react-icons/fa6";
import { SiGooglemaps } from "react-icons/si";




export default function Footer() {
    

    return (
        
        <div id="footer">

            <div id='footer'>
                © ONELAB

                <div id='footer-social-links'>
                    <a href='https://www.instagram.com/onelab.fisioterapia/' target='blank'><FaInstagram /></a>
                    <a href='https://www.google.com/maps/place/Onelab+%7C+Fisioterapia+y+Readaptaci%C3%B3n/@38.3576881,-0.4292366,17z/data=!3m1!4b1!4m6!3m5!1s0xd62392f91e81e5f:0x80b2a81831edebc3!8m2!3d38.3576881!4d-0.4292366!16s%2Fg%2F11w23drywj?entry=ttu'  target='blank'><SiGooglemaps /></a>
                </div>

                <div id='footer-policy-links'>
                    <a href='/policy/legal'>Aviso legal</a>
                    <a href='/policy/privacy'>Política de privacidad</a>
                </div>
            </div>
        </div>
    );
}