import './ConfirmationForm.css'
import React, { useState, useEffect} from 'react';
import { useNavigate } from 'react-router';
import { TextField  } from '@mui/material';
import Switch from '@mui/material/Switch';
import Loader from '../Loader/Loader';


import ApiManager from '../../ApiManager/ApiManager'


export default function ConfirmationForm({slot, setStep}) {

    const navigate = useNavigate();

    const [customerName, setCustomerName] = useState('');
    const [customerTelephone, setCustomerTelephone] = useState('');
    const [customerEmail, setCustomerEmail] = useState('');
    const [customerBirthYear, setCustomerBirthYear] = useState(null);
    const [customerOccupation, setCustomerOccupation] = useState('');
    const [customerAllergies, setCustomerAllergies] = useState('');
    const [customerComments, setCustomerComments] = useState('');
    const [firstSession, setFirstSession] = useState(false);
    const [loading, setLoading] = useState(false)


    const createReservation = (patient_id) => {
      ApiManager
          .createReservation({
            slot_id: slot.id,
            patient_id: patient_id
          })
          .then((response) => {
            navigate('/success/' + slot.id)
          })
          .catch((error) => {
            console.error(error)
          })
    }


      const confirmCheckout = () => {
        setLoading(true)

        const patient = {
          name: customerName,
          telephone: customerTelephone,
          email: customerEmail,
          birth_year: customerBirthYear,
          occupation: customerOccupation,
          allergies: customerAllergies,
          comments: customerComments
        }

        ApiManager
          .createPatient(patient)
          .then((response) => {
            createReservation(response.id)
          }).catch((error) => {
            console.error(error)
          })
  
        
      }

      const handleBack = () => {
          setStep('select-slot')
      }


      useEffect(() => {
        console.log(firstSession)
      }, [firstSession])

    return (
        <>
            <div id='reservation-confirmation-form-wrapper'>

              <div id='reservation-confirmation-form'>

                  <h3>Datos de contacto</h3>
                    <TextField type='text' className='reservation-confirmation-form-input' id='reservation-confirmation-form-name' margin="dense" name="name" label="Nombre" fullWidth onChange={(e) => setCustomerName(e.target.value)} />  
                    <TextField type='number' className='reservation-confirmation-form-input' id='reservation-confirmation-form-telephone'  margin="dense" name="name" label="Teléfono" fullWidth onChange={(e) => setCustomerTelephone(e.target.value)} />  
                    <TextField type='email' className='reservation-confirmation-form-input' id='reservation-confirmation-form-email'  margin="dense" name="name" label="Email" fullWidth onChange={(e) => setCustomerEmail(e.target.value)} />  
                    <span >Primera sesión</span>
                    <Switch label="Primera sesión" onChange={(e) => setFirstSession(!firstSession)}/>
                    
                    {firstSession && <>
                        <div id='reservation-confirmation-form-group-patient'>
                            <h3>Datos de paciente</h3>
                            <TextField type='number' className='reservation-confirmation-form-input' id='reservation-confirmation-form-birthyear'  margin="dense" name="birth_year" label="Año de nacimiento" fullWidth onChange={(e) => setCustomerBirthYear(e.target.value)} />
                            <TextField type='text' className='reservation-confirmation-form-input' id='reservation-confirmation-form-occupation'  margin="dense" name="occupation" label="Ocupación" fullWidth onChange={(e) => setCustomerOccupation(e.target.value)} />
                            <TextField type='text' className='reservation-confirmation-form-input' id='reservation-confirmation-form-alergies'  margin="dense" name="alergies" label="Alergias" fullWidth onChange={(e) => setCustomerAllergies(e.target.value)} />
                            <TextField type='text' multiline rows={4} className='reservation-confirmation-form-input' id='reservation-confirmation-form-patient-comments'  margin="dense" name="patient-comments" label="Comentarios" placeholder='' fullWidth onChange={(e) => setCustomerComments(e.target.value)}  InputLabelProps={{shrink: true}}/>
                        </div>


                        <div id='reservation-confirmation-form-group-anamnesis'>
                            <h3>Datos de consulta</h3>
                            <TextField type='text' className='reservation-confirmation-form-input' id='reservation-confirmation-form-reason'  margin="dense" name="name" label="Motivo de consulta" placeholder='"Fractura radio", "Dolor de espalda"...' fullWidth onChange={(e) => console.log(e.target.value)}  InputLabelProps={{shrink: true}}/>
                            <TextField type='text' multiline rows={4} className='reservation-confirmation-form-input' id='reservation-confirmation-form-comments'  margin="dense" name="session-comments" label="Comentarios" placeholder='"Dolor al despertar", "Molestia al coger objetos", "Pérdida de equilibrio"' fullWidth onChange={(e) => console.log(e.target.value)}  InputLabelProps={{shrink: true}}/>
                        </div>

                         
                      </>
                    }

                  <div >

                  <div id='reservation-confirmation-form-group-actions'>

                    <button className='reservation-form-button' id='reservation-form-button-cancel' onClick={() => handleBack()}>Atrás</button>
                    { !((customerName === '') || (customerTelephone === '')) &&
                        <button className='reservation-form-button' id='reservation-form-button-confirm' onClick={() => confirmCheckout()}>
                            {loading ? 
                              <Loader />
                              :
                              "Confirmar"
                            }
                        </button>
                    }

                  </div>
              </div>
              </div>

              

            </div>
        </> 
    );
}