import './Staff.css'
import Schedule from './Schedule';
import StaffInformation from './StaffInformation'
import React, { useState, useEffect } from 'react';
import {TableContainer, Table, TableHead,
    TableBody, TableRow, TableCell, Paper } from '@mui/material';
import ApiManager from '../../ApiManager/ApiManager';
import { MdOutlineSchedule } from "react-icons/md";
import { IoInformationCircleOutline } from "react-icons/io5";

import Tooltip from '@mui/material/Tooltip';


import moment from 'moment';
import 'moment/locale/es'; 


export default function Staff() {
    moment.locale('es');

    const [expandedRow, setExpandedRow] = useState(null);
    const [professionals, setProfessionals] = useState([]);
    const [selectedProfessional, setSelectedProfessional] = useState(null);
    const [selectedAction, setSelectedAction] = useState(null);


    const professionalTypes = {
        'physiotherapist': 'Fisioterapeuta'
    }

    
    useEffect(() => {
        ApiManager.getStaff()
            .then((response) => {
                setProfessionals(response)
            })
            .catch((error) => {
                console.error(error)
            })
    }, []);




    const handleScheduleClick = (index, professional) => {
        setSelectedProfessional(professional)
        setSelectedAction('schedule')
        setExpandedRow(index === expandedRow ? null : index);
    }

    const handleEditClick = (index, professional) => {
        setSelectedProfessional(professional)
        setSelectedAction('edit')
        setExpandedRow(index === expandedRow ? null : index);
    }



    return (
        <>
        <div id="staff-wrapper">
            <div id="staff-page-banner">
                <h1 id="staff-banner-title">Staff</h1>
            </div>

            <div id='staff-table'>
                <TableContainer component={Paper}>
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell align='center'></TableCell>
                                <TableCell align='center'><b>Nombre</b></TableCell>
                                <TableCell align='center'><b>Función</b></TableCell>
                                <TableCell align='center'></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {professionals.map((professional, index) => (
                            <React.Fragment key={index}>
                                <TableRow className="table-row" key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}   >
                                    <TableCell  align='center'>
                                        <div className='staff-row-photo-wrapper'>
                                            <img alt="staff-img" src={professional.image}></img>
                                        </div>
                                    </TableCell>
                                    <TableCell align='center'>
                                        <div>
                                            {professional.name} {professional.surname}
                                        </div>
                                    </TableCell>
                                    <TableCell align='center'>{professionalTypes[professional.type]}</TableCell>
                                    <TableCell>
                                        <div className='staff-actions-div'>
                                            <Tooltip arrow title="Información"><button className='reservation-form-button' onClick={() => handleEditClick(index, professional)}><IoInformationCircleOutline />&nbsp;Info</button></Tooltip>
                                            <Tooltip arrow title="Horario"><button className='reservation-form-button' onClick={() => handleScheduleClick(index, professional)}><MdOutlineSchedule />&nbsp;Horario</button></Tooltip>
                                        </div>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                        {expandedRow === index && selectedAction === 'schedule' && <Schedule professional={selectedProfessional} />}
                                        {expandedRow === index && selectedAction === 'edit' && <StaffInformation professional={selectedProfessional}  professionals={professionals} setProfessionals={setProfessionals} />}
                                </TableRow>
                            </React.Fragment>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>

        </div>

        
        </>
    );
}