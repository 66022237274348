import './Blog.css';
import { Helmet } from "react-helmet";
import React, { useState, useEffect } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import ArticleCard from '../../components/ArticleCard/ArticleCard';
import { useParams } from 'react-router';
import ApiManager from '../../ApiManager/ApiManager';
import { FaMagnifyingGlass } from "react-icons/fa6";

export default function Blog() {
  const params = useParams();
  const page = parseInt(params.page) || 1;

  const [articlesList, setArticlesList] = useState([]);
  const [articleCount, setArticleCount] = useState(null);
  const [keyword, setKeyword] = useState('');
  // const [page, ] = useState(initialPage);

  useEffect(() => {
    ApiManager.getArticlesPaginated(page)
      .then(response => {
        setArticlesList(prevArticlesList => [...prevArticlesList, ...response.posts]);
        setArticleCount(response.total_count);
      })
      .catch(error => {
        console.error(error);
      });
  }, [page]);

  const handleSubmit = (e) => {
    e.preventDefault();
    ApiManager.getArticleByKeyword(keyword)
      .then(response => {
        setArticlesList(response.posts);
        setArticleCount(response.total_count);
      })
      .catch(error => {
        console.error(error);
      });
  };

  return (
    <div id='blog-page'>
      <Helmet>
        <title>Blog | Onelab</title>
        <meta name="description" content="Blog" />
        <meta name="keywords" content="" />
        <link rel="canonical" href="https://onelab.es/blog" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <Navbar />

      <div id="blog-page-banner">
        <h1 id="blog-banner-title">Blog</h1>
        <form id='blog-search-wrapper' onSubmit={(e) => handleSubmit(e)}>
          <input type='text' name='search-term' onChange={(e) => setKeyword(e.target.value)} placeholder='Buscar...' />
          <FaMagnifyingGlass id='blog-search-icon' onClick={(e) => handleSubmit(e)} />
        </form>
      </div>

      <div id='blog-content'>
        {articlesList.length > 0 ? (
          articlesList.map((article, index) => (
            <ArticleCard key={index} article={article} />
          ))
        ) : ''}
      </div>

      <div id='page-search-action-wrapper'>
        {page * 9 > 9 && (
          <a href={'/blog/' + (page-1)}><button className='button-blog-page-next'>Cargar anteriores</button></a>
        )}
        <p>Mostrando {Math.min(articleCount, page * 9)}/{articleCount}</p>
        {page * 9 < articleCount && (
          <a href={'/blog/' + (page+1)}><button className='button-blog-page-next'>Cargar siguientes</button></a>
        )}
      </div>
    </div>
  );
}
