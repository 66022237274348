import './ServiceTypeForm.css'


export default function ServiceTypeForm({ setServiceType, setStep }) {



    const handleSelect = (service) => {
        setServiceType(service)
        setStep('select-professional')
    }

    const handleBack = () => {
        window.location = '/'
    }


    return (
        <>
        <div className="reservation-form-div" id='reservation-servicetype-form'>
            <div 
                className='reservation-form-item servicetype-form-item' 
                onClick={() => handleSelect('Fisioterapia')}
                style={{
                    backgroundImage: "url('https://onelab-media-files.s3.eu-south-2.amazonaws.com/services/physical_therapy.jpg')"
                }}
                >
               <p>Fisioterapia</p>
            </div>
        </div>
        <div className="reservation-form-actions">
                <button className='reservation-form-button' id='reservation-form-button-cancel' onClick={() => handleBack()}>Atrás</button>
        </div>
        </>
    );
}