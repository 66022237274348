import './HomeBanner.css';
import { useNavigate } from 'react-router';

import React from 'react';

const HomeBanner = () => {

    const navigate = useNavigate();

    return (
        <div id='home-banner'>
            <video id="banner-video" src="https://onelab-media-files.s3.eu-south-2.amazonaws.com/banner.mp4" autoPlay muted loop>
                Your browser does not support HTML5 video.
            </video>
            <h1 id="home-banner-title"><span id='home-banner-title-name'>ONELAB</span><br></br>Centro de Fisioterapia <br></br>y Readaptación  Deportiva<br></br>en Alicante</h1>

            <div id="home-banner-actions">
                <button onClick={() => navigate('/reservation')}className='home-banner-actions-button'>Haz tu reserva</button>
                {/* <button onClick={() => console.log('/#equipo')} className='home-banner-actions-button'>Conócenos</button> */}
            </div>
        </div>
        )
};

export default HomeBanner;