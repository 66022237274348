import './Contact.css';
import React from 'react';
import { FaWhatsapp, FaPhoneAlt } from "react-icons/fa";

const Contact = () => {

    return (
        <div id='home-contact'>
            <div id="home-contact-description">
                <h2 className="home-section-title">Contacto</h2>
                <p>Encuéntranos en el CC Venecia, en Avenida Costablaca 21, 1ª planta local 20, 03540, Cabo de las Huertas, Alicante</p>
                <p>ó</p>
                <div id='home-contact-actions'>
                    <a href="tel:+34627198632"><div className='home-contact-button'><FaPhoneAlt size={24}/><p>Llámanos</p></div></a>
                    <a href="https://wa.me/34627198632" target='blank'><div className='home-contact-button'><FaWhatsapp size={24} /><p>Escríbenos un Whatsapp</p></div></a>
                </div>
            </div>
            <div id='home-map-wrapper'>
                <iframe 
                    id='home-map'
                    title='map'
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3128.6238173528795!2d-0.43181152408895757!3d38.357688071844294!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd62392f91e81e5f%3A0x80b2a81831edebc3!2sOnelab%20%7C%20Fisioterapia%20y%20Readaptaci%C3%B3n!5e0!3m2!1ses!2ses!4v1720600973233!5m2!1ses!2ses" 
                    allowFullScreen="" 
                    loading="lazy" 
                    referrerPolicy="no-referrer-when-downgrade">
                </iframe>
            </div>
                
        </div>
        )
};

export default Contact;