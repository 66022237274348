import './Reservation.css'
import React, { useState, useEffect } from 'react';
import {Helmet} from "react-helmet";
import NavBar from '../../components/Navbar/Navbar';
import ServiceTypeForm from '../../components/ServiceTypeForm/ServiceTypeForm';
import ProfessionalForm from '../../components/ProfessionalForm/ProfessionalForm';
import SlotForm from '../../components/SlotForm/SlotForm';
import DateForm from '../../components/DateForm/DateForm';
import ConfirmationForm from '../../components/ConfirmationForm/ConfirmationForm';
import ApiManager from '../../ApiManager/ApiManager'
import moment from 'moment';
import 'moment/locale/es'; 


export default function Reservation() {
    moment.locale('es');

    const [serviceType, setServiceType] = useState(null);
    const [professionalList, setProfessionalList] = useState([]);
    const [professional, setProfessional] = useState(null);
    const [dateSlots, setDateSlots] = useState(null);
    const [slot, setSlot] = useState(null);
    const [slots, setSlots] = useState([]);
    const [step, setStep] = useState('select-service')


    useEffect(() => {

        ApiManager.getPhysiotherapists()
            .then(response => {setProfessionalList(response)})
            .catch((error) => {console.error(error)})

        ApiManager.getSlots()
            .then(response => {setSlots(response)})
            .catch((error) => {console.error(error)})

    }, [])


    const stepMessage = {
        'select-service': 'Selecciona servicio',
        'select-professional': 'Selecciona profesional',
        'select-date': 'Selecciona día',
        'select-slot': 'Selecciona hora',
        'confirmation': 'Introduce tus datos'
    }
    
    const steps = {
        'select-service':       <ServiceTypeForm 
                                    setServiceType={setServiceType} 
                                    setStep={setStep}/>,
        'select-professional':  <ProfessionalForm  
                                    professionalList={professionalList}
                                    setProfessional={setProfessional} 
                                    setStep={setStep}/>,
        'select-date':          <DateForm 
                                    slots={slots}
                                    professional={professional}
                                    setDateSlots={setDateSlots} 
                                    setStep={setStep}/>,
        'select-slot':          <SlotForm 
                                    setSlot={setSlot} 
                                    dateSlots={dateSlots}
                                    setStep={setStep}/>,
        'confirmation':         <ConfirmationForm 
                                    serviceType={serviceType}
                                    professional={professional}
                                    slot={slot}
                                    setStep={setStep}/>
    }

    
    return (

        <><NavBar color={'black'}/>
        
        <div id="reservation-page">
            <Helmet>
                <title>Reserva | Onelab</title>
            </Helmet>
            <div className="reservation-form-div-title">
                    <h3>{stepMessage[step]}</h3>
                    {professional && 
                        <>
                        <br></br>
                        <p>
                            Reserva de {serviceType} con {professional.name}  
                            {slot && ' el ' + moment(slot.start_time).format('dddd D/M') + ' a las ' + moment(slot.start_time).format('HH:mm')} 
                        </p>
                        </>
                    }
                
            </div>

            { steps[step] }

        </div>
        </>
    );
}