import './DashboardNavBar.css'
import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { MdOutlineArticle } from "react-icons/md";
import { IoMdLogOut } from "react-icons/io";
import { IoCalendarOutline } from "react-icons/io5";
import { MdOutlinePersonalInjury, MdOutlineAccountBalance } from "react-icons/md";


import { FaPeopleGroup } from "react-icons/fa6";
import { logoPath } from '../../media/logos';



export default function DashboardNavBar() {

    return (
        <Navbar collapseOnSelect expand="lg" bg="none" id='dashboard-navbar'>
            <Navbar.Brand href="/" id="dashboard-navbar-logo-wrapper">
                <img id="dashboard-navbar-logo" src={logoPath['black']} alt="onelab-logo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="dashboard-navbar-menu" id='navbar-toggle-btn'/>
            <Navbar.Collapse id="dashboard-navbar-menu">
                <Nav  id="dashboard-navbar-menu-list">
                    <Nav.Link className='nav-link' href="/dashboard/agenda"><IoCalendarOutline />&nbsp;Agenda</Nav.Link>
                    <Nav.Link className='nav-link' href="/dashboard/staff"><FaPeopleGroup />&nbsp;Staff&nbsp;</Nav.Link>
                    <Nav.Link className='nav-link' href="/dashboard/patients"><MdOutlinePersonalInjury />&nbsp;Pacientes</Nav.Link>
                    <Nav.Link className='nav-link' href="/dashboard/payments"><MdOutlineAccountBalance />&nbsp;Pagos</Nav.Link>
                    <Nav.Link className='nav-link' href="/dashboard/new-article"><MdOutlineArticle />&nbsp;Nuevo artículo</Nav.Link>
                    <Nav.Link className='nav-link' href="/logout"><IoMdLogOut />&nbsp;Logout</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}