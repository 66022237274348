import getStripe from '../../lib/getStripe';
import { PAGE_URL } from '../../ApiManager/ApiManager';
import { useParams} from 'react-router';


export default function PaymentForm() {

    const params = useParams();

    async function handleCheckout() {
        const stripe = await getStripe();
        const { error } = await stripe.redirectToCheckout({
          lineItems: [
            {
              price: 'price_1PETeDIxYuwSrfhT1Q7aytaV',
              quantity: 1,
            },
          ],
          mode: 'payment',
          successUrl: PAGE_URL + `/success/${params.slot_id}?paid=true`,
          cancelUrl: PAGE_URL + `/success/${params.slot_id}?paid=false`,
        });
        console.warn(error.message);
    }

    handleCheckout();
}