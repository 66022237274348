
import './SocialRunning.css'
import { Helmet } from "react-helmet";
import React from 'react';
import { FaInstagram, FaWhatsapp } from "react-icons/fa";






export default function SocialRunning() {

    

    return (
        
        <div id="social-running-page">
            <Helmet>
                <title>One Social Running Club Alicante</title>
                <meta name="description" content="Club de running social en Alicante" />
                <meta name="keywords" content="running, run, correr, club, alicante, entrenamiento" />
                <link rel="canonical" href="https://onelab.es/one-social-running-club" />
                <meta name="robots" content="index, follow" />
            </Helmet>

            <div id='social-running-header'>
                <img id='social-running-logo' src='https://onelab-media-files.s3.eu-south-2.amazonaws.com/one-social-running-folder/logo-main.png' alt='logo'></img>
            </div>
            <div id='social-running-content'>

                <div className='social-running-section' id='social-running-title'>

                    <span className='social-running-outline'>¡Empezamos el 26 de agosto!</span>
                    <h1>One Social Running Club</h1>
                    <a href='https://www.instagram.com/one.social.running/' target='blank'><FaInstagram size={30} color='#E4405F'/></a>
                </div>
                <div className='social-running-section'>
                    <h2>¿Qué es un club de running social?</h2>
                    <p>Un club de running social es una comunidad de entusiastas del deporte que se reúnen regularmente para correr en grupo. Promovemos la actividad física en un entorno amigable y motivador, ideal para corredores de <span className='social-running-outline'>todos los niveles</span>. Unirse a un club de running social ofrece numerosos beneficios, como mejorar la salud cardiovascular, aumentar la resistencia y reducir el estrés, todo mientras se disfruta de la camaradería y el apoyo de otros corredores. Además, organizamos eventos, entrenamientos y carreras que fomentan un estilo de vida activo y saludable. Participar en un club de running social no solo mejora tu condición física, sino que también te permite <span className='social-running-outline'>conocer gente nueva</span> y formar parte de una comunidad apasionada por el deporte.</p>
                </div>

                <div className='social-running-section'>
                    <h2>¿Cuánto cuesta entrenar en One Social Running?</h2>
                    <p>Nada. El objetivo del club no es sacar un beneficio económico. Nuestro objetivo es <span className='social-running-outline'>crear una comunidad</span> de apasionados del deporte, <span className='social-running-outline'>generar hábitos de vida saludables</span> y (lo más importante) <span className='social-running-outline'>crear buen rollo</span>. 
                        <br></br>En alguna ocasión se organizarán eventos en los que, aparte de entrenar, se hará un post-entreno con merendola y bebidas para recuperarnos y pasar un buen rato. En estas ocasiones, puede que la inscripción conlleve un pago simbólico para cubrir los gastos.</p>
                </div>

                <div className='social-running-section'>
                    <h2>¿Cuándo son los entrenamientos?</h2>
                    <p>Nos juntamos para entrenar 2 días a la semana: <span className='social-running-outline'>Lunes y Miércoles, a las 19:30</span>.</p>
                    <p>Dónde: <span className='social-running-outline'>Avenida Niza 25, Playa de San Juan</span> (enfrente del restaurante Niza).</p>
                </div>

                <div className='social-running-section'>
                    <h2>¿Cómo me apunto?</h2>
                    <p>Únete a nuestro grupo privado de whatsapp, donde se informará de todos los entrenamientos (prometemos no hacer spam).</p>
                </div>

                <div className='social-running-section' id='social-running-social-section-cta'>
                    <a 
                        href="https://chat.whatsapp.com/EC7644cIPmP89adnEPlg2Z" 
                        class="whatsapp-button" 
                        target="_blank"
                        rel="noreferrer"
                        id='social-running-cta'>
                        <FaWhatsapp /> &nbsp;Unirme a grupo de Whatsapp
                    </a>
                </div>

                <div className='social-running-section' id='social-running-final-section'>
                    <h2>¡Nos vemos corriendo!</h2>
                </div>
                
            </div>
        </div>
    );
}