import './Login.css'
import {Helmet} from "react-helmet";
import Navbar from '../../components/Navbar/Navbar';
import LoginForm from '../../components/LoginForm/LoginForm';



export default function Login() {

    return (
        <div id="login-page">
            <Helmet>
                <title>Login | Onelab</title>
            </Helmet>
            <Navbar />
            <LoginForm />
      </div>
    );
}