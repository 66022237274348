import './Service.css'
import {Helmet} from "react-helmet";
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import { useParams } from 'react-router';
import { services } from '../../constants'
import Parser from 'html-react-parser';


export default function Service() {

    const params = useParams();
    const serviceLongTitle = services[params.service][1]
    const serviceDescription = services[params.service][2]
    const serviceContent = services[params.service][3]
    const serviceKeyWords = services[params.service][4]

    return params.service && (
        <>
        <div id='service-page'>
            <Helmet>
                <title>{serviceLongTitle}</title>
                <meta name="description" content={serviceDescription}/>
                <meta name="robots" content="index, follow"/>
                <meta name="keywords" content={serviceKeyWords}/>
            </Helmet>
            <Navbar />
            <div id='service'>
                <div id='service-header'>
                    <h1 id='service-title'>{serviceLongTitle}</h1>
                </div>
                <div id='service-subheader'>
                    <h2 id='service-subtitle'>{serviceDescription}</h2>
                </div>
                <div id='service-description'>
                    {Parser(serviceContent)}
                </div>
            </div>
            <div>

            </div>
            
        </div>
        <div id='service-cta-div'>
            <a href='/reservation'>
                <div id='service-cta-btn'>Reserva tu cita</div>
            </a>
        </div>
        <Footer />
        </>
        
    );
}