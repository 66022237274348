import './ProfessionalForm.css'
import React, { useEffect } from 'react';

export default function ProfessionalForm({professionalList, setProfessional, setStep}) {


    useEffect(() => {
        setProfessional(null)
    }, [setProfessional])
    
    

    const handleSelect = (professional) => {
        setProfessional(professional)
        setStep('select-date')
    }

    const handleBack = () => {
        setStep('select-service')
    }


    return (
        <>
            <div className="reservation-form-div" id='reservation-professional-form'>

                {professionalList && professionalList.map((p, i) => {
                    return (
                        <div 
                            key={i} 
                            className='reservation-form-item reservation-professional-form-item' 
                            onClick={() => handleSelect(p)}
                            style={{
                                backgroundImage: "url('"+ p.image +"')"
                            }}
                            >
                            <p>{p.name}</p>
                        </div>
                    )
                })}
            </div>
            <div className="reservation-form-actions">
                    <button className='reservation-form-button' id='reservation-form-button-cancel' onClick={() => handleBack()}>Atrás</button>
            </div>
                
        </>
    );
}