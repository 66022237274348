import './ProfessionalsList.css';
import React from 'react';

const ProfessionalsList = () => {

    return (
        <div id='professionals-list-wrapper'>
            <h2 className="home-section-title" id='professionals-list-title'>El equipo</h2>
            <div id='professionals-list'>

                <div className='professional-card'>
                    <img className='professional-card-photo' alt='professional-maria' src='https://onelab-media-files.s3.eu-south-2.amazonaws.com/professional_photos/maria.png'></img>
                    <div className='professional-card-description'>
                        <div  className='professional-card-header'>
                            <div className='professional-card-name'>
                                <b>María</b>
                            </div> 
                            <div className='professional-card-role'>
                                Fisioterapeuta
                            </div>
                        </div>
                        <div>
                            <p>Suelo pélvico</p>
                            <p>HIIT</p>
                            <p>Readpatación</p>
                            <p>Entrenamiento personal</p>
                        </div>
                    </div>
                </div>

                <div className='professional-card'>
                    <img className='professional-card-photo' alt='professional-nestor' src='https://onelab-media-files.s3.eu-south-2.amazonaws.com/professional_photos/nestor.png'></img>
                    <div className='professional-card-description'>
                        <div>
                            <div className='professional-card-name'>
                                <b>Néstor</b>
                            </div> 
                            <div className='professional-card-role'>
                                Fisioterapeuta
                            </div>
                        </div>
                        <div>
                            <p>Suelo pélvico</p>
                            <p>HIIT</p>
                            <p>Readpatación</p>
                            <p>Entrenamiento personal</p>
                        </div>
                    </div>
                </div>

            </div>
            

        </div>
    );
};

export default ProfessionalsList;