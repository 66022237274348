import './Reviews.css';
import React, { useState, useEffect } from 'react';
// import ApiManager from '../../ApiManager/ApiManager';
import { FaStar } from "react-icons/fa";
import { MdOutlineReviews } from "react-icons/md";


const Reviews = () => {
    const reviews = [
        {
            "author_name": "Carmen Sanjuán",
            "author_url": "https://www.google.com/maps/contrib/113615590773702261908/reviews",
            "language": "es",
            "original_language": "es",
            "profile_photo_url": "https://lh3.googleusercontent.com/a/ACg8ocIQMStCpj4iBW18WKBmanGXeiAPOrtTOxlo1bjxFTQnPhbS0pWT=s128-c0x00000000-cc-rp-mo",
            "rating": 5,
            "relative_time_description": "Hace 5 meses",
            "text": "Primera vez que voy y muy contenta. Venía con un esguince en el tobillo y sobrecarga en las piernas. Me atendió Iñaki, muy atento y profesional. Me ayudó mucho con la movilidad y activación en el tobillo y al finalizar me dio una tabla con ejercicios para recuperar y fortalecer más. Agradecida✌🏻",
            "time": 1704706932,
            "translated": false
        },
        {
            "author_name": "Haz De Tu Vida Un Sueño Y De Tu Sueño Una Realidad",
            "author_url": "https://www.google.com/maps/contrib/111089240426818954321/reviews",
            "language": "es",
            "original_language": "es",
            "profile_photo_url": "https://lh3.googleusercontent.com/a-/ALV-UjXEagbwLBM3h7RRuwqqnqOYQQ5fm0Rr4ktqWzEOqaT7Q1hNl7g=s128-c0x00000000-cc-rp-mo",
            "rating": 5,
            "relative_time_description": "Hace 4 meses",
            "text": "Hace unos meses solo conocía el nombre.\nHoy después  de hacer una rehabilitación  de prótesis  de cadera a domicilio.  Lo que veo es un equipo muy profesional  y un fisio que se convirtió en amigo.\nGracias Iñaki.  Gracias  ferreti.",
            "time": 1708331182,
            "translated": false
        },
        {
            "author_name": "Sergio",
            "author_url": "https://www.google.com/maps/contrib/106739436154096240515/reviews",
            "language": "es",
            "original_language": "es",
            "profile_photo_url": "https://lh3.googleusercontent.com/a-/ALV-UjXlKxRv5rH-2rNSF0sH64brdZen8lRVnBzgdhP59GGcHkA0kurecQ=s128-c0x00000000-cc-rp-mo-ba4",
            "rating": 5,
            "relative_time_description": "Hace 8 meses",
            "text": "Un gran profesional Iñaki. He ido hoy para descargar lumbares y por una contractura en el trapecio, y el trabajo ha sido excelente.\nMe habeis ganado como cliente. Gracias.\n\nPor otra parte, perfecta la ubicación, el local se ve muy nuevo y muy bien preparado. Ambiente idóneo.",
            "time": 1696501126,
            "translated": false
        },
        {
            "author_name": "Javi Carrera Palacio",
            "author_url": "https://www.google.com/maps/contrib/105305357982689269875/reviews",
            "language": "es",
            "original_language": "es",
            "profile_photo_url": "https://lh3.googleusercontent.com/a-/ALV-UjU5GYAPj5Eaz6C-zprZuem8-LMMY52SlPRUAlrNDlnD-i29PQw=s128-c0x00000000-cc-rp-mo",
            "rating": 5,
            "relative_time_description": "Hace un año",
            "text": "Estupendo equipo de profesionales.\nSe nota que disfrutan de su trabajo y así se lo transmiten al paciente, con dedicación e interés total para solucionar cualquier lesión/patología.\nMuy actualizados en cuanto a herramientas y técnicas de trabajo.\nEncantado con el trato recibido.",
            "time": 1687358532,
            "translated": false
        },
        {
            "author_name": "Tomás Salama Cancer",
            "author_url": "https://www.google.com/maps/contrib/118426718187047585777/reviews",
            "language": "es",
            "original_language": "es",
            "profile_photo_url": "https://lh3.googleusercontent.com/a-/ALV-UjV37WxOL7XUF_cuNPxGyreB30cck5_E9pmZACNT7R5c_UcgOjQ=s128-c0x00000000-cc-rp-mo",
            "rating": 5,
            "relative_time_description": "Hace 3 meses",
            "text": "Muy buena clínica, muy profesional y de gran ayuda con lesiones y entrenamientos",
            "time": 1711379537,
            "translated": false
        }
    ];
    const [selectedReview, setSelectedReview] = useState(0);
    const [fadeIn, setFadeIn] = useState(true);

    // useEffect(() => {
    //     ApiManager.getReviews()
    //         .then((response) => {
    //             setReviews(response);
    //         })
    //         .catch((error) => {
    //             console.error(error);
    //         });
    // }, []);

    useEffect(() => {
        if (reviews.length > 0) {
            const executeFunction = () => {
                setFadeIn(false); // Start fade-out

                setTimeout(() => {
                    setSelectedReview((prevSelectedReview) => {
                        if (prevSelectedReview === reviews.length - 1) {
                            return 0;
                        } else {
                            return prevSelectedReview + 1;
                        }
                    });
                    setFadeIn(true); // Start fade-in
                }, 500); // Match this duration with the transition duration
            };

            const intervalId = setInterval(executeFunction, 6000); // Change interval to match animation time
            return () => clearInterval(intervalId);
        }
    }, [reviews.length]);

    return ( 
        <div id='home-reviews-wrapper'>
            {reviews.length > 0 && (
                <div id='home-reviews'>
                    <div id='reviews-section-title-wrapper'>
                        <h2  className="home-section-title" id='reviews-title'>
                            Reseñas
                        </h2>
                        <a href="https://g.page/r/CcPr7TEYqLKAEAE/review" target='blank'><div id='review-button'><MdOutlineReviews  size={24}/><p>Déjanos tu reseña</p></div></a>
                        
                    </div>
                    <div className={`review ${fadeIn ? 'active' : 'hidden'}`}>
                        <div id='review-name-wrapper'>
                            <div id='review-name-initial'>{reviews[selectedReview].author_name.substring(0, 1)}</div>
                            {reviews[selectedReview].author_name}
                        </div>
                        <div id='review-rating-wrapper'>
                            {[...Array(reviews[selectedReview].rating)].map((x, i) => (
                                <FaStar color='gold' size={24} key={i} />
                            ))}
                        </div>
                        <div id='review-comment-wrapper'>
                            {reviews[selectedReview].text}
                        </div>
                    </div>
                </div>
            )}
        </div>

        
    );
};

export default Reviews;
