
import './LoginForm.css'
import React, { useState } from 'react';
import ApiManager from '../../ApiManager/ApiManager'
import { setToken } from '../../auth/Token';
import Loader from '../Loader/Loader';


export default function LoginForm() {


    const [register, setRegister ] = useState(false);
    const [error, setError] = useState();
    const [loggingIn, setLoggingin] = useState(false)


    const clickRegister = (e) => {
        setRegister(!register);
      };


    const createDefaultSchedule = (user_id) => {
        const data = {user_id: user_id}
        ApiManager.createDefaultSchedule(data)
            .then( response => {
                // console.log('schedules created')
            }).catch(error => {
                setError(error.response?.data?.Error || 'An error with schedules occurred');
            });
    }


    const handleLogin= (e) => {
        e.preventDefault();
        setLoggingin(true);
        setError(null); // Clear previous errors
        

        const form_data = {
            "email": e.target.email.value,
            "password": e.target.password.value,
        }

        ApiManager
            .login(form_data)
            .then( response => {
                setToken(response.token);
                window.location = '/dashboard'
            }).catch(error => {
                setError(error.response?.data?.Error || 'An error occurred');
                setLoggingin(false);
            });
    };

    const handleRegister= (e) => {
        e.preventDefault()
        setLoggingin(true)
        setError(null)

        if (e.target.password.value !== e.target.confirm_password.value){
            setError('Las contraseñas no son iguales')
            setLoggingin(false)
            return;
        }

        const form_data = {
            "email": e.target.email.value,
            "password": e.target.password.value,
            "type": e.target.type.value,
            "name": e.target.name.value,
            "center_code": e.target.center_code.value,
        }

        ApiManager
            .register(form_data)
            .then((response)=> {
                setToken(response.token)
                createDefaultSchedule(response.id)
                window.location = '/dashboard'
                
            }).catch(error => {
                setError(error.response?.data?.Error || 'An error occurred')
                setLoggingin(false)
            });
    
      };

    return (
        <div id='login-form-div'>
            <form id="login-form" component="form" onSubmit={register? handleRegister : handleLogin} onFocus={() => setError('')}>

                <div id='login-form-header'>
                    <h2>{register ? "Crear una cuenta" : "Iniciar sesión"}</h2>
                </div>

                {error &&
                    <div id='error-div'>
                        {error}
                    </div>
                }

                <div className='form-group'>
                    <input required className='login-form-input' name='email' id="email-input-login" type='email' placeholder='Email' />
                </div>

                <div className='form-group'>
                    <input required className='login-form-input' name='password' id="password-input-login" type='password'  placeholder='*****'/>
                </div>

                { register &&
                    <>
                        <div className='form-group'>
                            <p className="login-form-label">Repite contraseña</p>
                            <input required className='login-form-input' name='confirm_password' id="confirm-password-input" type='password' placeholder='*****'  />
                        </div>
                        <div className='form-group'>
                            <p className="login-form-label">Nombre</p>
                            <input required className='login-form-input' name='name' id="name-input" type='text' placeholder='Nombre'  />
                        </div>
                        <div className='form-group'>
                            <p className="login-form-label">Puesto</p>
                            <select className='login-form-input' id="type-input" name="type">
                                <option value="physiotherapist">Fisioterapeuta</option>
                                <option value="other">Otro</option>
                            </select>
                        </div>
                        <div className='form-group'>
                            <p className="login-form-label">Código centro</p>
                            <input required className='login-form-input' name='center_code' id="code-input" type='text' placeholder='Código centro'  />
                        </div>
                    </>
                }


                <div id="login-button-wrapper">

                    <div id="register-link" className='form-group' onClick={clickRegister}>
                        { register ? "¿Ya tienes cuenta? Inicia sesión" : "Crear usuario"}
                    </div>
                    
                        
                    <div className='form-group'>
                        <button id="login-btn" variant="contained" type="submit"> 
                            {loggingIn ?
                                <Loader color='white' />
                                :
                                register ? "Registrarme" : "Entrar"
                            }
                        </button>
                    </div>

                </div>
            </form>

        </div>
    );
}