import './NewPaymentDialog.css'
import React, { useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import moment from 'moment';
import 'moment/locale/es'; 
import Tooltip from '@mui/material/Tooltip';
import { FaSave } from "react-icons/fa";


export default function EditPaymentDialog({ payment, setPayment, open, setOpen, handleEditPayment }) {

    const concepts = ['Sesión de fisioterapia', 'Bono 5 sesiones fisioterapia', 'Bono 10 sesiones fisioterapia']
    const payment_methods = ['Tarjeta', 'Efectivo', 'Transferencia']
    
    useEffect(() => {
        if (payment?.date) {
            setPayment(prevPayment => ({
                ...prevPayment,
                date: moment(prevPayment.date).format('YYYY-MM-DD')
            }));
        }
    }, [payment?.date, setPayment]);

    const handleEditPaymentChange = (e) => {
        const { name, value } = e.target;
        setPayment(prevPayment => ({ ...prevPayment, [name]: value }));
    };



    return ( payment &&
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>Editar pago</DialogTitle>
            <DialogContent>
                <TextField margin="dense" name="nif" label="NIF" fullWidth value={payment.nif} onChange={handleEditPaymentChange} />
                <TextField margin="dense" name="name" label="Nombre" fullWidth value={payment.name} onChange={handleEditPaymentChange} />
                <TextField margin="dense" name="date" type='date' label='Fecha' fullWidth value={moment(payment.date).format('YYYY-MM-DD')} onChange={handleEditPaymentChange} InputLabelProps={{shrink: true}}/>
                <FormControl margin="dense" fullWidth>
                    <InputLabel id="concept-select-label">Concepto</InputLabel>
                    <Select
                        labelId="concept-select-label"
                        name="concept"
                        value={payment.concept}
                        onChange={handleEditPaymentChange}
                        label="Concepto"
                    >
                        {concepts.map((concept) => (
                            <MenuItem key={concept} value={concept}>
                                {concept}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <TextField InputLabelProps={{ shrink: true }} margin="dense" name="tax" label="Impuesto" type="number" fullWidth value={payment.tax} onChange={handleEditPaymentChange} />
                <TextField InputLabelProps={{ shrink: true }} margin="dense" name="quantity" label="Precio" type="number" fullWidth value={payment.quantity} onChange={handleEditPaymentChange} />

                <FormControl margin="dense" fullWidth>
                    <InputLabel id="concept-select-label">Método pago</InputLabel>
                    <Select
                        labelId="method-select-label"
                        name="payment_method"
                        value={payment.payment_method}
                        onChange={handleEditPaymentChange}
                        label="Método pago"
                    >
                        {payment_methods.map((m) => (
                            <MenuItem key={m} value={m}>
                                {m}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

            </DialogContent>
            <DialogActions>
                <Button id="new-payments-cancel-button" onClick={() => setOpen(false)} color="secondary">Cancelar</Button>
                <Tooltip arrow title="Guardar"><Button id="new-payments-confirm-button" onClick={() => handleEditPayment(payment.id, payment)} color="primary"><FaSave /></Button></Tooltip>
            </DialogActions>
        </Dialog>
    );
}
