import './ReservationRecord.css';
import React, { useState, useEffect } from 'react';
import ApiManager from '../../ApiManager/ApiManager';

import { useLocation } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/es'; 
import Loader from '../Loader/Loader'
import Tooltip from '@mui/material/Tooltip';
import { FaSave } from "react-icons/fa";




export default function ReservationRecord() {
    moment.locale('es');
    const location = useLocation();

    const params = new URLSearchParams(location.search);
    const slotId = params.get('slot_id');
    const [loading, setloading] = useState(false);
    const [reservation, setReservation] = useState(null);
    const [comments, setComments] = useState(null);
    const [plan, setPlan] = useState(null);


    const saveSession = (e) => {
        setloading(true)
        e.preventDefault();
        ApiManager.saveReservation(reservation.id, {comments: comments, plan: plan})
            .then((response) => {
                setReservation(response)
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                setloading(false)
            })
    }

    useEffect(() => {

        ApiManager.getReservation(slotId)
            .then((response) => {
                setReservation(response)
            })
            .catch((error) => {
                console.error(error)
            })

    }, [slotId])

    useEffect(() => {
        if (reservation) {
            setComments(reservation.comments)
            setPlan(reservation.plan)
        }

    }, [reservation])

    return ( reservation && 
        <div id='reservation-record-wrapper'>
            <form id='reservation-record'>
                <div>
                    <h2 id='reservation-record-title-wrapper'>
                        <span id='reservation-record-title'>Registro de sesión</span>
                        <>
                            {moment(reservation.start_time).format('DD/MM/YY H:mm')}
                            &nbsp;- {moment(reservation.end_time).format('H:mm')}
                        </>
                    </h2>
                </div>
                <div className='reservation-record-form-group'>
                    <p className='reservation-record-form-label'>Nombre</p>
                    <p><b>{reservation.name}</b> </p>
                </div>
                <div className='reservation-record-form-group'>
                    <p className='reservation-record-form-label'>Planificación</p>
                    <textarea className='reservation-record-form-input' id='reservation-record-form-input-plan' onChange={(e) => setPlan(e.target.value)} defaultValue={plan} rows={12}/>
                </div>
                <div className='reservation-record-form-group'>
                    <p className='reservation-record-form-label'>Comentarios</p>
                    <textarea className='reservation-record-form-input' id='reservation-record-form-input-comments' onChange={(e) => setComments(e.target.value)} defaultValue={comments} rows={6}/>
                </div>
                <div className='reservation-record-form-group' id='reservation-record-form-group-actions'>
                    <Tooltip arrow title='Guardar'>
                    <button className='reservation-record-button' onClick={(e) => saveSession(e)}>
                        {loading ? 
                            <Loader color='white'/>
                            :
                            <FaSave />
                        }
                    </button>
                    </Tooltip>
                </div>
            </form>
        </div>
    );
};