import './Article.css'
import {Helmet} from "react-helmet";
import React, { useState, useEffect } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import { useParams } from 'react-router';
import Parser from 'html-react-parser';
import moment from 'moment';

import ApiManager from '../../ApiManager/ApiManager'


export default function Article() {

    const params = useParams();

    const [selectedArticle, setSelectedArticle] = useState()

    useEffect(() => {
        ApiManager.getArticle(params.slug)
            .then(response => {
                setSelectedArticle(response)
            })
            .catch(
                setSelectedArticle(  
                    {
                        'title': 'The Title',
                        'subtitle': 'The subtitle',
                        'author': 'Néstor Jiménez',
                        'slug': 'the-slug',
                        'created_at': '24 Jan, 2024 ',
                        'content': 'Lorem ipsum dolor sit amet consectetur adipiscing elit, dapibus fermentum mi dictumst est venenatis nisl, ut augue scelerisque laoreet lacinia faucibus. Lobortis tempor nascetur quis sed mattis semper est malesuada etiam cras pretium egestas, dictumst lacus feugiat interdum condimentum nulla eros et laoreet gravida. Consequat vulputate magna non nunc viverra vivamus curae proin odio lectus fusce taciti, suscipit auctor a congue mollis cras nam et class vehicula platea, iaculis eget id sollicitudin porta quam purus venenatis urna rutrum nulla.\n\nHac rhoncus diam ante nisi vehicula ad quis, potenti quisque per cum fames morbi montes nisl, est fermentum semper suscipit a dapibus. Eleifend ultrices habitant magna potenti nec tempus id in cubilia nisl convallis, felis primis libero est posuere vehicula fringilla cum senectus odio magnis, ac platea nostra maecenas sapien dictumst lacinia blandit nullam porta. Conubia proin feugiat in ultrices nascetur per, inceptos magna platea ac cursus donec nunc, primis litora condimentum duis tincidunt.',
                        'img_url': 'https://images.unsplash.com/photo-1521575107034-e0fa0b594529?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8JTIwcG9zdHxlbnwwfHwwfHx8MA%3D%3D'
                    }
                )
            )
        }, [params.slug])

    return selectedArticle && (
        <div id='article-page'>
            <Helmet>
                <title>{selectedArticle.title}</title>
                <meta name="description" content={selectedArticle.subtitle}/>
                <meta name="robots" content="index, follow"/>
                <meta name="keywords" content={selectedArticle.key_words}/>
                <link rel="canonical" href={"https://onelab.es/blog/article/" + selectedArticle.slug} />
            </Helmet>
            <Navbar />
            <div id='article'>
                <div id='article-header'>
                    <p id='article-date'>{moment(selectedArticle.created_at).format('DD MMM YYYY')}</p>
                    <h1 id='article-title'>{selectedArticle.title}</h1>
                    <h2 id='article-subtitle'>{selectedArticle.subtitle}</h2>
                </div>
                <div id='article-image-wrapper'>
                    <img id='article-image' src={selectedArticle.image} alt={selectedArticle.slug}></img>
                </div>
                <div id='article-content'>
                    {Parser(selectedArticle.content)}
                </div>
            </div>
        </div>
    );
}