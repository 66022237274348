import './InvoiceDialog.css'
import React, { useRef } from 'react';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper } from '@mui/material';
import { Dialog, DialogContent, DialogActions } from '@mui/material';
import moment from 'moment';
import 'moment/locale/es'; 
import { FaPrint } from "react-icons/fa6";
import Tooltip from '@mui/material/Tooltip';

import { logoPath } from '../../media/logos';
import { useReactToPrint } from 'react-to-print';


export default function InvoiceDialog({ open, setOpen, payment }) {

    const total = (1 + payment.tax/100) * payment.quantity

    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: `Factura_${payment.id}`,
    });


    return ( 
        <Dialog open={open} onClose={() => setOpen(false)}>
            
            <DialogContent>

                <div id="invoice-page" ref={componentRef}>
                    <div id='invoice-header'>
                        <div id='invoice-logo-wrapper'>
                            <img alt="onelab-logo" src={logoPath['black']} id='invoice-logo'></img>
                        </div>
                        <div id='invoice-business-info'>
                            ONELAB FISIOTERAPIA Y READAPATACIÓN DEPORTIVA S.L.<br />
                            AV. COSTABLANCA 25, 03540, ALICANTE<br />
                            C-999999999<br />
                            Teléfono: 965965965
                        </div>
                    </div>

                    <div id='invoice-subheader'>
                        <div id='invoice-client-info'>
                            <h6 className='section-title'>Datos cliente</h6>
                            Nombre: <span className='invoice-data-point'>{payment.name}</span><br />
                            NIF/CIF: <span className='invoice-data-point'>{payment.nif}</span><br />
                        </div>

                        <div id='invoice-invoice-info'>
                            <h6 className='section-title'>Datos factura</h6>
                            ID: <span>{payment.id}</span><br />
                            Fecha: <span>{moment(payment.date).format('DD-MM-YYYY')}</span><br />
                        </div>
                    </div>

                    <div id='invoice-body'>
                        <TableContainer component={Paper}>
                            <Table size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell><b>Concepto</b></TableCell>
                                        <TableCell align="left"><b>Precio</b></TableCell>
                                        <TableCell align="left"><b>IVA</b></TableCell>
                                        <TableCell align="left"><b>Total</b></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow className="table-row">
                                        <TableCell component="th" scope="row">{payment.concept}</TableCell>
                                        <TableCell align="left">{parseFloat(payment.quantity).toFixed(2)}€</TableCell>
                                        <TableCell align="left">{parseFloat(payment.tax).toFixed(2)}%</TableCell>
                                        <TableCell align="left">{parseFloat(total).toFixed(2)}€</TableCell>
                                    </TableRow>
                                    <TableRow className="table-row">
                                        <TableCell component="th" scope="row"></TableCell>
                                        <TableCell align="left"></TableCell>
                                        <TableCell align="left"><b>Total</b></TableCell>
                                        <TableCell align="left"><b>{parseFloat(total).toFixed(2)}€</b></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <div id='invoice-payment-info'>
                            <h6 className='section-title'>Forma de pago</h6>
                            <span>{payment.payment_method}</span>
                        </div>
                    </div>
                </div>

            </DialogContent>
            <DialogActions>
                <button id="new-payments-cancel-button" onClick={() => setOpen(false)} color="secondary">Cancelar</button>
                <Tooltip arrow title="Imprimir"><button className='reservation-form-button' id='invoice-print-button' onClick={handlePrint}><FaPrint /></button></Tooltip>
            </DialogActions>
        </Dialog>
    );
}