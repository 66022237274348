import './StaffInformation.css'
import React, { useState } from 'react';
import moment from 'moment';
import 'moment/locale/es'; 
import Loader from '../Loader/Loader';
import {TableCell} from '@mui/material';
import ApiManager from '../../ApiManager/ApiManager'
import { TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { FaSave } from "react-icons/fa";


export default function StaffInformation({professional, professionals, setProfessionals}) {
    moment.locale('es');

    const [loading, setLoading] = useState(false);
    const [saved, setSaved] = useState(true);


    const [professionalInfo, setProfessionalInfo] = useState({
        name: professional.name,
        email: professional.email,
        telephone: professional.telephone,
        type: professional.type,
        image: professional.image
    })

    const updateInfo = (key, value) => {
        setProfessionalInfo((prevInfo) => ({
            ...prevInfo,
            [key]: value,
          }));
    }

    const handleFileChange = (e) => {
        setLoading(true)
        const file = e.target.files[0];
        updateInfo('image', URL.createObjectURL(file))
        ApiManager.uploadStaffImage(file, professional.id + '.png')
          .then((response) => {
            updateInfo('image', 'https://onelab-media-files.s3.eu-south-2.amazonaws.com/staff/' + professional.id + '.png')
          })
          .catch((error) => {
            
          })
          .finally(() => {
            setLoading(false)
        })
      };

    const handleSubmitData = () => {

        setLoading(true)
        
        ApiManager
            .saveStaff(professional.id, professionalInfo)
            .then((response) => {
                const updatedIndex = professionals.findIndex(p => p.id === professional.id);
                const updatedProfessionals = [...professionals];
                updatedProfessionals[updatedIndex] = response;
                setProfessionals(updatedProfessionals);
                setSaved(true);
            })
            .catch((error) => console.error(error))
            .finally(() => {
                setLoading(false)
            })
    }


    return (
        <TableCell colSpan={4}>
            <div id="staffinformation-wrapper">
                <div id="staff-information-page-banner">
                    <h2 id="staff-information-banner-title">Información de <span className='patient-name'>{professionalInfo.name}</span></h2>

                    { !saved && 
                        <Tooltip arrow title="Guardar">
                            <button id='schedule-save-button' onClick={() => handleSubmitData()} disabled={saved}>
                                {loading ? 
                                    <Loader /> 
                                    : 
                                    <FaSave />
                                }
                            </button>
                        </Tooltip>
                    
                    }

                </div>

                <div id='staff-information-content'>
                    {professionalInfo.image !== null  ?
                        <img alt='staff' src={professionalInfo.image} onClick={() => document.getElementById('file-upload').click()}></img>
                        :
                        <div className="staff-no-photo-div" onClick={() => document.getElementById('file-upload').click()}>Añadir foto</div>
                    }

                    <form id='staff-information-form' onChange={() => setSaved(false)}>
                        <TextField size="small" margin="dense" name="name" type='text'  label='Nombre' fullWidth defaultValue={professional.name} onChange={(e) => updateInfo('name', e.target.value)} InputLabelProps={{shrink: true}}/>
                        <TextField size="small" margin="dense" name="email" type='text'  label='Email' fullWidth defaultValue={professional.email} onChange={(e) => updateInfo('email', e.target.value)} InputLabelProps={{shrink: true}}/>
                        <TextField size="small" margin="dense" name="telephone" type='text'  label='Teléfono' fullWidth defaultValue={professional.telephone} onChange={(e) => updateInfo('telephone', e.target.value)} InputLabelProps={{shrink: true}}/>
                        <FormControl size="small" margin="dense" fullWidth>
                            <InputLabel id="type-label">Tipo</InputLabel>
                            <Select
                                labelId="type-label"
                                name="type"
                                defaultValue={professional.type}
                                onChange={(e) => updateInfo('type', e.target.value)}
                                label="Tipo"
                            >
                                <MenuItem value="physiotherapist">Fisioterapeuta</MenuItem>
                                <MenuItem value="trainer">Entrenador</MenuItem>
                            </Select>
                        </FormControl>
                            <input
                                type='file'
                                name="image"
                                id="file-upload"
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                            />
                    </form>

                </div>
            </div>
        </TableCell>
    );
}