import './NewArticleForm.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import ApiManager from '../../ApiManager/ApiManager'



export default function NewArticleForm() {

  const charLimit = 8000;

  const navigate = useNavigate();

  const [error, setError] = useState(null)
  const [title, setTitle] = useState(null);
  const [subtitle, setSubtitle] = useState(null);
  const [author, setAuthor] = useState(null);
  const [content, setContent] = useState(null);
  const [slug, setSlug] = useState(null);
  const [keyWords, setKeywords] = useState(null);
  const [image, setImage] = useState(null);
  const [charCount, setCharCount] = useState(0);

  const handleSubmit = () => {
    const fields = [title, subtitle, author, content, slug, image];

    
    
    if (fields.includes('') || fields.includes(null) || fields.includes(false)) {
      setError('Rellena todos los campos')
      return;
    } 

    ApiManager.postArticle({
        title: title,
        subtitle: subtitle,
        author: author,
        content: content, 
        slug: slug,
        image: image,
        key_words: keyWords
      }).then((response)=> {
        navigate('/blog/article/' + slug);
      }
      ).catch((error) => {
        setError(error.message)
      })
    
  }

  const handleEditorChange = (event, editor) => {
      const data = editor.getData();
      if (data.length <= charLimit) {
          setContent(data);
          setCharCount(data.length);
      } else {
          editor.setData(content); 
      }
  };


  const handleFileChange = (e) => {
    const file = e.target.files[0];
    ApiManager.uploadArticleImage(file, slug + '.png')
      .then((response) => {
        setImage('https://onelab-media-files.s3.eu-south-2.amazonaws.com/blog/' + slug + '.png')
      })
      .catch((error) => {
        setError(error.message)
      })
  };


  return (
    <>
      <div className='new-article-page-header'>
        <h2 className='new-article-page-title'>Nuevo artículo</h2>
        <button className='reservation-form-button' id='reservation-form-button-confirm' onClick={handleSubmit}>Guardar</button>
      </div>
      
      <div id='new-article-form'>

        {
          error && 
            <div className='error-div new-article-form-group-full'>
              {error}
            </div>
        }

        <div className='new-article-form-group'>
            <p className='new-article-form-label'>Título</p>
            <input className='new-article-form-input' type='text' placeholder='Título del artículo' onChange={(e) => setTitle(e.target.value)}/>
        </div>

        <div className='new-article-form-group'>
            <p className='new-article-form-label'>Autor</p>
            <input className='new-article-form-input' type='text' placeholder='Nombre del autor/a' onChange={(e) => setAuthor(e.target.value)}/>
        </div>

        <div className='new-article-form-group new-article-form-group-full'>
            <p className='new-article-form-label'>Subtítulo</p>
            <input className='new-article-form-input' type='text' placeholder='Subtítulo del artículo (máx: 100 caracteres)' onChange={(e) => setSubtitle(e.target.value)} maxLength={100}/>
        </div>

        <div className='new-article-form-group'>
            <p className='new-article-form-label'>Slug (para url)</p>
            <input className='new-article-form-input' type='text' placeholder='nombre-de-articulo' onChange={(e) => setSlug(e.target.value)}/>
        </div>

        <div className='new-article-form-group'>
            <p className='new-article-form-label'>Palabras clave</p>
            <input className='new-article-form-input' type='text' placeholder='ligamento cruzado anterior, lca, rodilla, ...' onChange={(e) => setKeywords(e.target.value)} maxLength={100}/>
        </div>

        <div className='new-article-form-group'>
            <p className='new-article-form-label'>Imagen</p>
            <input className='' type='file' onChange={handleFileChange} id='new-article-image-input'/>
        </div>


        <div className='new-article-form-group new-article-form-group-full'>
          <p className='new-article-form-label'>Contenido</p>
          <CKEditor
            editor={ ClassicEditor }
            config={{
              heading: {
                options: [
                  { model: 'paragraph', title: 'Párrafo', class: 'ck-heading_paragraph' },
                  { model: 'heading2', view: 'h2', title: 'Título sección', class: 'ck-heading_heading2' },
                  // Add more heading options if needed
                ]
              },
              toolbar: {
                items: [
                  'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', '|', 'insertTable', 'undo', 'redo'
                ]
              },
              removePlugins: [
                'ImageUpload',
                'EasyImage',
                'Base64UploadAdapter',
                'ImageInsert',
              ]
            }}
            data=""
            onReady={ ( editor ) => {
              setContent('')
              setCharCount(0);
            } }
            onChange={handleEditorChange}
          />
          <div>
                {charCount}/{charLimit} caracteres
            </div>
            {charCount >= charLimit && (
                <div style={{ color: 'red' }}>¡Limite de caracteres alcanzado!</div>
            )}
        </div>

        

      </div>
    </>
  );
}