import './Dashboard.css'
import {Helmet} from "react-helmet";
import React  from 'react';
import { useParams } from 'react-router';
import DashboardNavBar from '../../components/DashboardNavBar/DashboardNavBar';
import Agenda from '../../components/Agenda/Agenda';
import NewArticleForm from '../../components/NewArticleForm/NewArticleForm';
import PatientDirectory from '../../components/PatientDirectory/PatientDirectory';
import Staff from '../../components/Staff/Staff';
import ReservationRecord from  '../../components/ReservationRecord/ReservationRecord';
import Payments from '../../components/Payments/Payments';



export default function Dashboard() {

    const params = useParams();

    const menus = {
        'agenda': <Agenda />,
        'new-article': <NewArticleForm />,
        'patients': <PatientDirectory />,
        'staff': <Staff />,
        'reservation-record': <ReservationRecord />,
        'payments': <Payments />
    }

    const menu = params.menu;

    return (
        <div id="dashboard-page">
            <Helmet>
                <title>Dashboard | Onelab</title>
            </Helmet>
            
            <div id='dashboard-content'>
                <DashboardNavBar />
                { menu ? menus[menu] : menus['agenda'] }
            </div>
        </div>
    );
}