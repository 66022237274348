import './PatientHistory.css';
import React from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import moment from 'moment';
import 'moment/locale/es';
import { FaEye } from "react-icons/fa";
import Tooltip from '@mui/material/Tooltip';

export default function PatientHistory({ patient, history }) {

    const handleRecordClick = (h) => {
        window.location.href = '/dashboard/reservation-record?slot_id=' + h.slot_id;
    };

    return (
        <TableCell colSpan={6}>
            <div className="table-row-expanded">
                <h2 className='patient-name-wrapper'>Historial de &nbsp;<span className='patient-name'>{patient.name}</span></h2>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell><b>Fecha</b></TableCell>
                            <TableCell align="left"><b>Inicio</b></TableCell>
                            <TableCell align="left"><b>Final</b></TableCell>
                            <TableCell align="center"><b>Acciones</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {history.map((h, i) => (
                            <TableRow className="table-row" key={h.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">{moment(h.start_time, 'DD/MM/YYYY HH:mm').format('D-M-YYYY')}</TableCell>
                                <TableCell align="left">{moment(h.start_time, 'DD/MM/YYYY HH:mm').format('H:mm')}</TableCell>
                                <TableCell align="left">{moment(h.end_time, 'DD/MM/YYYY HH:mm').format('H:mm')}</TableCell>
                                <TableCell align="center" className='patient-history-actions'>
                                    <Tooltip arrow title="Ver"><button className='patient-history-button' id='patient-direcctory-invoice-button' onClick={() => handleRecordClick(h)}><FaEye /></button></Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </TableCell>
    );
};
