import './Services.css';
import React from 'react';
import { services } from '../../constants'

const Services = () => {

    const serviceEntries = Object.entries(services);

    return (
        <div id='home-section-services'>
            <h2 className="home-section-title" id='home-services-title'>Nuestros servicios</h2>
            <div id='home-services'>
                {serviceEntries.map(([key, description], index) => (
                    <a key={index} href={'/services/' + key}>
                        <div className='service-div'>
                            {description[0]}
                        </div>
                    </a>
                ))}
            </div>
        </div>
    );
};

export default Services;