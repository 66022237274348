import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import Home from "./pages/Home/Home";
import Reservation from "./pages/Reservation/Reservation";
import Blog from "./pages/Blog/Blog";
import Service from "./pages/Service/Service";
import Article from "./pages/Article/Article";
import Dashboard from "./pages/Dashboard/Dashboard";
import Login from "./pages/Login/Login";
import Logout from "./pages/Logout/Logout";
import Success from "./pages/Success/Success";
import Countdown, { calculateTimeLeft } from "./components/Countdown/Countdown";
import SocialRunning from "./pages/SocialRunnning/SocialRunning";
import PaymentForm from './components/PaymentForm/PaymentForm';

import Policy from "./pages/Policy/Policy";

import { getToken } from "./auth/Token";


function App() {

  const token = getToken();

  if ( calculateTimeLeft().days > 30){
    return <>
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={ <Countdown /> } />
          <Route path="/blog" element={ <Blog /> } />
          <Route path="/blog/:page" element={ <Blog /> } />
          <Route path="/blog/article/:slug" element={ <Article /> } />
          <Route path="/login" element={ <Login /> } />
          <Route path="/logout" element={ <Logout /> } />
          <Route path="/dashboard" element={token ? (<Dashboard />) : (<Navigate replace to={"/login"} />)} />
          <Route path="/dashboard/:menu" element={token ? (<Dashboard />) : (<Navigate replace to={"/login"} />)} />
          <Route path="/one-social-running-club" element={<SocialRunning />} />
        </Routes>
      </BrowserRouter>
    </>
  }
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={ <Home /> } />
          <Route path="/reservation" element={ <Reservation /> } />
          <Route path="/blog" element={ <Blog /> } />
          <Route path="/blog/:page" element={ <Blog /> } />
          <Route path="/blog/article/:slug" element={ <Article /> } />
          <Route path="/services/:service" element={<Service />} />
          <Route path="/login" element={ <Login /> } />
          <Route path="/logout" element={ <Logout /> } />
          <Route path="/success/:slot_id" element={ <Success /> } />
          <Route path="/pay-online/:slot_id" element={ <PaymentForm /> } />
          <Route path="/one-social-running-club" element={<SocialRunning />} />
          <Route path="/policy/:policy" element={ <Policy />} />
          <Route path="/dashboard" element={token ? (<Dashboard />) : (<Navigate replace to={"/login"} />)} />
          <Route path="/dashboard/:menu" element={token ? (<Dashboard />) : (<Navigate replace to={"/login"} />)} />

        </Routes>
      </BrowserRouter>
    </>)
  }


export default App;
