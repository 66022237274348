import './Agenda.css';
import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/es'; 
import 'react-big-calendar/lib/css/react-big-calendar.css'; 
import ApiManager from '../../ApiManager/ApiManager';
import ReservationModal from '../ReservationModal/ReservationModal';
import SlotModal from '../SlotModal/SlotModal';
import Loader from '../Loader/Loader';
import { generateColor } from '../../constants'
import { FaWhatsapp } from "react-icons/fa6";




const MyEvent = ({ event, reservations, professionals }) => {
    
    const reservation = reservations.find(r => r.slot_id === event.slot_id);

    return (
        <div className="custom-event-container">
            {reservation ? reservation.name : ''}
        </div>
    );
};


export default function Agenda() {
    moment.locale('es');
    const isMobile = () => window.innerWidth <= 768;

    const [slots, setSlots] = useState([]);
    const [selectedSlots, setSelectedSlots] = useState([]);
    const [professionalList, setProfessionalList] = useState([]);
    const [selectedProfessionals, setSelectedProfessionals] = useState([]);
    const [showReservationModal, setShowReservationModal] = useState(false);
    const [showSlotModal, setShowSlotModal] = useState(false);
    const [evnt, setEvnt] = useState();
    const [reservations, setReservations] = useState([]);
    const [sendingReminders, setSendingReminders] = useState(false);

    useEffect(() => {
        ApiManager.getPhysiotherapists()
            .then(response => {
                setProfessionalList(response);
                setSelectedProfessionals(response.map(p => String(p.id)));
            }).catch((error) => {
                console.error(error);
            });

        ApiManager.getSlots()
            .then(response => {
                const formattedSlots = response.map(slot => ({
                    status: slot.status,
                    user_id: slot.user_id, 
                    slot_id: slot.id,
                    start: new Date(slot.start_time),
                    end: new Date(slot.end_time),
                }));
                setSlots(formattedSlots);
            }).catch((error) => {
                console.error(error);
            });

        ApiManager.getReservations()
            .then((response) => {
                setReservations(response);
            })
            .catch((error) => {
                console.error(error);
            });

    }, []);

    useEffect(() => {
        const updatedSlots = slots.filter(slot => selectedProfessionals.includes(String(slot.user_id)));
        setSelectedSlots(updatedSlots);
    }, [selectedProfessionals, slots]);

    const localizer = momentLocalizer(moment);

    const views = isMobile() ? [Views.DAY] : [Views.WEEK, Views.DAY]
    const defaultView = isMobile() ? Views.DAY : Views.WEEK
    const minTime = new Date();
    minTime.setHours(7, 0, 0); 

    const maxTime = new Date();
    maxTime.setHours(22, 0, 0); 

    const formats = {
        timeGutterFormat: (date, culture, localizer) =>
            localizer.format(date, 'HH:mm', culture),
        eventTimeRangeFormat: ({ start, end }, culture, localizer) =>
            localizer.format(start, 'HH:mm', culture) + ' - ' + localizer.format(end, 'HH:mm', culture),
    };

    const eventPropGetter = (event) => {
        let opacity;
        switch (event.status) {
            case 'FREE':
                opacity = 0.3;
                break;
            default:
                opacity = 1;
        }
        return {
            style: {
                fontSize: '10pt',
                border: '1px solid rgba(255,255,255)',
                padding: '1px',
                color: 'white',
                backgroundColor: generateColor(event.user_id),
                opacity: opacity
            },
        };
    };

    const onSelectEvent = (e) => {
        setEvnt(e);
        setShowReservationModal(!showReservationModal);
    };

    const handleSelectSlot = (e) => {
        setEvnt(e);
        setShowSlotModal(!showSlotModal);
    };

    const MyEventWrapper = (props) => {
        return <MyEvent {...props} professionals={professionalList} reservations={reservations} />;
    };

    const handleSelectProfessional = (id) => {
        if (selectedProfessionals.includes(id)) {
            const updatedList = selectedProfessionals.filter(pId => pId !== id);
            setSelectedProfessionals(updatedList);
        } else {
            setSelectedProfessionals([...selectedProfessionals, id]);
        }
    };

    const handleClickSendReminder = () => {
        setSendingReminders(true);
        ApiManager.sendReminders()
            .then((response) => {
                alert('Recordatorios enviados');
            })
            .catch((error) => {
                // console.error(error);
                alert('error');
            })
            .finally(() => {
                setSendingReminders(false);
            });
    };

    return (
        <>
            <div id="dashboard-agenda-wrapper">
                <div id="dashboard-agenda-options">
                    {professionalList.map((p, i) => {
                        return (
                            <div className={`agenda-professional-checkbox background-${generateColor(i)}`} style={{backgroundColor:generateColor(p.id)}} key={i}>
                                <input
                                    type="checkbox"
                                    id={p.id}
                                    name={p.id}
                                    value={p.id}
                                    checked={selectedProfessionals.includes(String(p.id))}
                                    onChange={(e) => handleSelectProfessional(e.target.value)}
                                />
                                <label htmlFor={p.id}>&nbsp;{p.name}</label><br />
                            </div>
                        );
                    })}
                    
                </div>

                {showReservationModal && <ReservationModal professionals={professionalList} reservations={reservations} event={evnt} onClose={() => setShowReservationModal(!showReservationModal)} />}
                {showSlotModal && <SlotModal professionals={professionalList} event={evnt} onClose={() => setShowSlotModal(!showSlotModal)} />}

                <div id="dashboard-agenda">
                    <Calendar
                        id='dashboard-calendar'
                        onSelectEvent={(e) => onSelectEvent(e)}
                        onSelectSlot={(e) => handleSelectSlot(e)}
                        localizer={localizer}
                        events={selectedSlots}
                        startAccessor="start"
                        endAccessor="end"
                        min={minTime} 
                        max={maxTime} 
                        views={views}
                        defaultView={defaultView}
                        formats={formats}
                        eventPropGetter={eventPropGetter}
                        selectable={true}
                        components={{
                            event: MyEventWrapper, // Use custom event component
                        }}
                    />
                </div>
            </div>

            <div id='dashboard-agenda-actions'>
                <div id='send-reminder-button' onClick={() => handleClickSendReminder()}>
                    {sendingReminders ? <Loader /> : <FaWhatsapp size={20}/> }
                </div>
            </div>
        </>
    );
}
