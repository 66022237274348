import React, { useState } from 'react';
import './ReservationModal.css';
import ApiManager from '../../ApiManager/ApiManager';
import moment from 'moment';
import 'moment/locale/es';
import { FaPlusCircle, FaRegTrashAlt } from "react-icons/fa";
import { IoArrowBack } from "react-icons/io5";
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from '@mui/material';

export default function ReservationModal({ professionals, reservations, event, onClose }) {
    moment.locale('es');

    const reservation = reservations.find(r => r.slot_id === event.slot_id);
    const professional = professionals.find(p => p.id === event.user_id);

    const [patientName, setPatientName] = useState(null);
    const [patientEmail, setPatientEmail] = useState(null);
    const [patientTelephone, setPatientTelephone] = useState(null);
    const [comments, setComments] = useState(null);

    const createPatient = () => {
        if (event.slot_id === null || patientName === null || patientTelephone === null) {
            alert('Rellena todos los campos');
            return;
        }

        const form_data = {
            'name': patientName,
            'telephone': patientTelephone,
            'email': patientEmail,
        };

        ApiManager.createPatient(form_data)
            .then((response) => {
                const patientId = response.id;
                createReservation(patientId);
            })
            .catch((error) => console.error(error));
    };

    const createReservation = patientId => {
        const form_data = {
            'slot_id': event.slot_id,
            'patient_id': patientId,
            'comments': comments
        };

        ApiManager.createReservation(form_data)
            .then((response) => {
                window.location = '/dashboard';
            })
            .catch((error) => console.error(error));
    };

    const deleteReservation = (slot_id) => {
        ApiManager.deleteReservation(slot_id)
            .then((response) => {
                window.location = '/dashboard';
            })
            .catch((error) => console.error(error));
    };

    const deleteSlot = (slot_id) => {
        ApiManager.deleteSlot(slot_id)
            .then((response) => {
                window.location = '/dashboard';
            })
            .catch((error) => console.error(error));
    };

    return (
        <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle className="modal-header">
                <span className='modal-header-text'>{event.status === 'BOOKED' ? 'Info reserva' : 'Nueva reserva'}</span>
                <span className='modal-header-time'>{moment(event.start).format('ddd D, H:mm') + '-' + moment(event.end).format('LT')}</span>
            </DialogTitle>
            <DialogContent id='reservation-modal-body'>
                <div className='reservation-modal-form-group' id='professional-form-group'>
                    <p className='reservation-form-label'>Profesional: <span id='reservation-form-professional-name'>{professional && professional.name}</span></p>
                    {event.status === 'BOOKED' && <a href={`/dashboard/reservation-record?slot_id=` + reservation?.slot_id}><Button variant="contained" color="primary">Ver sesión</Button></a>}
                </div>
                <div className='reservation-modal-form-group'>
                    <TextField margin="dense" name="name" type='text' label='Nombre' fullWidth defaultValue={reservation?.name} onChange={(e) => setPatientName(e.target.value)} InputProps={{ readOnly: event.status === 'BOOKED' }} InputLabelProps={{ shrink: true }} />
                </div>
                <div className='reservation-modal-form-group'>
                    <TextField margin="dense" name="telephone" type='tel' label='Teléfono' fullWidth defaultValue={reservation?.telephone} onChange={(e) => setPatientTelephone(e.target.value)} InputProps={{ readOnly: event.status === 'BOOKED' }} InputLabelProps={{ shrink: true }} />
                </div>
                <div className='reservation-modal-form-group'>
                    <TextField margin="dense" name="email" type='email' label='Email' fullWidth defaultValue={reservation?.email} onChange={(e) => setPatientEmail(e.target.value)} InputProps={{ readOnly: event.status === 'BOOKED' }} InputLabelProps={{ shrink: true }} />
                </div>
                <div className='reservation-modal-form-group'>
                    <TextField margin="dense" name="comments" multiline rows={4} label='Comentarios sesión' fullWidth defaultValue={reservation?.comments} onChange={(e) => setComments(e.target.value)} InputProps={{ readOnly: event.status === 'BOOKED' }} InputLabelProps={{ shrink: true }} />
                </div>
            </DialogContent>
            <DialogActions id='reservation-modal-actions'>
                <button className='modal-form-button' id='reservation-modal-form-button-back' onClick={onClose}><small><IoArrowBack />&nbsp;Atrás</small></button>
                {event.status === 'BOOKED' ?
                    <button className='modal-form-button' id='reservation-modal-form-button-delete' onClick={() => deleteReservation(reservation.id)}><small><FaRegTrashAlt />&nbsp;Eliminar reserva</small></button>
                    :
                    <>
                        <button className='modal-form-button' id='reservation-modal-form-button-delete' onClick={() => deleteSlot(event.slot_id)}><small><FaRegTrashAlt />&nbsp;Eliminar hueco</small></button>
                        {patientName !== null && patientTelephone && <button className='modal-form-button' id='reservation-modal-form-button-update' onClick={() => createPatient()}><small><FaPlusCircle />&nbsp;Añadir</small></button>}
                    </>
                }
            </DialogActions>
        </Dialog>
    );
}
