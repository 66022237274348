
import './Policy.css'
import {Helmet} from "react-helmet";
import React from 'react';
import { useParams } from 'react-router';
import Parser from 'html-react-parser';

import NavBar from '../../components/Navbar/Navbar';


const legal = `
<div>
    <h2>LEY DE LOS SERVICIOS DE LA SOCIEDAD DE LA INFORMACIÓN (LSSI)</h2>
    <p>
      ONELAB, responsable del sitio web, en adelante RESPONSABLE, pone a disposición de los usuarios el presente documento, con el que pretende dar cumplimiento a las obligaciones dispuestas en la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico (LSSICE), BOE N.º 166, así como informar a todos los usuarios del sitio web respecto a cuáles son las condiciones de uso.
    </p>
    <p>
      Toda persona que acceda a este sitio web asume el papel de usuario, comprometiéndose a la observancia y cumplimiento riguroso de las disposiciones aquí dispuestas, así como a cualquier otra disposición legal que fuera de aplicación.
    </p>
  </div>

  <div>
    <h3>1. DATOS IDENTIFICATIVOS</h3>
    <ul>
      <li>Nombre de dominio: <a href="https://onelab.es">ONELAB.ES</a></li>
      <li>Nombre comercial: ONELAB</li>
      <li>NIF: 74375243M</li>
      <li>Domicilio social: Avenida Costablanca, 39, 03540 ALICANTE (Alacant)</li>
      <li>Teléfono: 627198632</li>
      <li>E-mail: <a href="mailto:info@onelab.es">INFO@ONELAB.ES</a></li>
    </ul>
  </div>

  <div>
    <h3>2. DERECHOS DE PROPIEDAD INTELECTUAL E INDUSTRIAL</h3>
    <p>
      El sitio web, incluyendo a título enunciativo pero no limitativo su programación, edición, compilación y demás elementos necesarios para su funcionamiento, los diseños, logotipos, texto y/o gráficos, son propiedad del RESPONSABLE o, si es el caso, dispone de licencia o autorización expresa por parte de los autores.
    </p>
    <p>
      Todos los contenidos del sitio web se encuentran debidamente protegidos por la normativa de propiedad intelectual e industrial, así como inscritos en los registros públicos correspondientes.
    </p>
    <p>
      Independientemente de la finalidad para la que fueran destinados, la reproducción total o parcial, uso, explotación, distribución y comercialización, requiere en todo caso la autorización escrita previa por parte del RESPONSABLE.
    </p>
  </div>

  <div>
    <h3>3. EXENCIÓN DE RESPONSABILIDADES</h3>
    <p>
      El RESPONSABLE se exime de cualquier tipo de responsabilidad derivada de la información publicada en su sitio web siempre que no tenga conocimiento efectivo de que esta información haya sido manipulada o introducida por un tercero ajeno al mismo o, si lo tiene, haya actuado con diligencia para retirar los datos o hacer imposible el acceso a ellos.
    </p>
    <h4>Uso de Cookies</h4>
    <p>
      Este sitio web puede utilizar cookies técnicas (pequeños archivos de información que el servidor envía al ordenador de quien accede a la página) para llevar a cabo determinadas funciones que son consideradas imprescindibles para el correcto funcionamiento y visualización del sitio.
    </p>
  </div>

  <div>
    <h3>4. LEY APLICABLE Y JURISDICCIÓN</h3>
    <p>
      Para la resolución de todas las controversias o cuestiones relacionadas con el presente sitio web o de las actividades en él desarrolladas, será de aplicación la legislación española, a la que se someten expresamente las partes, siendo competentes para la resolución de todos los conflictos derivados o relacionados con su uso los Juzgados y Tribunales del domicilio del USUARIO o el lugar del cumplimiento de la obligación.
    </p>
  </div>
`

const privacy = `
<div>
    <h2>1. INFORMACIÓN AL USUARIO</h2>
    <p><strong>¿Quién es el responsable del tratamiento de tus datos personales?</strong></p>
    <p>
      ONELAB es el RESPONSABLE del tratamiento de los datos personales del USUARIO y le informa de que estos datos serán tratados de conformidad con lo dispuesto en el Reglamento (UE) 2016/679, de 27 de abril (GDPR), y la Ley Orgánica 3/2018, de 5 de diciembre (LOPDGDD).
    </p>
    <p><strong>¿Para qué tratamos tus datos personales?</strong></p>
    <ul>
      <li>Para mantener una relación comercial con el usuario.</li>
      <li>Remisión de comunicaciones comerciales publicitarias por e-mail, fax, SMS, MMS, redes sociales o cualquier otro medio electrónico o físico, presente o futuro.</li>
      <li>Realizar estudios de mercado y análisis estadísticos.</li>
      <li>Tramitar encargos, solicitudes, dar respuesta a las consultas o cualquier tipo de petición realizada por el USUARIO.</li>
      <li>Remitir el boletín informativo online.</li>
    </ul>
    <p><strong>¿Por qué motivo podemos tratar tus datos personales?</strong></p>
    <ul>
      <li>Con el consentimiento del USUARIO: remisión de comunicaciones comerciales y del boletín informativo.</li>
      <li>Por interés legítimo del RESPONSABLE: realizar estudios de mercado, análisis estadísticos, tramitar encargos, solicitudes, etc.</li>
    </ul>
    <p><strong>¿Durante cuánto tiempo guardaremos tus datos personales?</strong></p>
    <p>
      Se conservarán durante no más tiempo del necesario para mantener el fin del tratamiento o existan prescripciones legales que dictaminen su custodia.
    </p>
    <p><strong>¿A quién facilitamos tus datos personales?</strong></p>
    <p>
      No está prevista ninguna comunicación de datos personales a terceros salvo, si fuese necesario para el desarrollo y ejecución de las finalidades del tratamiento, a nuestros proveedores de servicios relacionados, con los cuales el RESPONSABLE tiene suscritos los contratos de confidencialidad y de encargado de tratamiento exigidos por la normativa vigente de privacidad.
    </p>
    <p><strong>¿Cuáles son tus derechos?</strong></p>
    <ul>
      <li>Derecho a retirar el consentimiento en cualquier momento.</li>
      <li>Derecho de acceso, rectificación, portabilidad y supresión de sus datos, y de limitación u oposición a su tratamiento.</li>
      <li>Derecho a presentar una reclamación ante la autoridad de control (www.aepd.es) si consideras que el tratamiento no se ajusta a la normativa vigente.</li>
    </ul>
    <p><strong>Datos de contacto para ejercer tus derechos:</strong></p>
    <p>
        ONELAB Avda Costablanca 39, 03540 ALICANTE (Alacant). E-mail: <a href="mailto:INFO@ONELAB.ES">INFO@ONELAB.ES</a>
    </p>
  </div>

  <div>
    <h2>2. CARÁCTER OBLIGATORIO O FACULTATIVO DE LA INFORMACIÓN FACILITADA POR EL USUARIO</h2>
    <p>
      Los USUARIOS, mediante la marcación de las casillas correspondientes y la entrada de datos en los campos marcados con un asterisco (*) en el formulario de contacto o presentados en formularios de descarga, aceptan expresamente y de forma libre e inequívoca, que sus datos son necesarios para atender su petición, siendo voluntaria la inclusión de datos en los campos restantes. El USUARIO garantiza que los datos personales facilitados al RESPONSABLE son veraces y se hace responsable de comunicar cualquier modificación de los mismos.
    </p>
    <p>
      El RESPONSABLE informa de que todos los datos solicitados a través del sitio web son obligatorios para la prestación de un servicio óptimo al USUARIO. En caso de que no se faciliten todos los datos, no se garantiza que la información y servicios facilitados sean completamente ajustados a sus necesidades.
    </p>
  </div>

  <div>
    <h2>3. MEDIDAS DE SEGURIDAD</h2>
    <p>
      De conformidad con lo dispuesto en las normativas vigentes en protección de datos personales, el RESPONSABLE está cumpliendo con todas las disposiciones de las normativas GDPR y LOPDGDD para el tratamiento de los datos personales de su responsabilidad, y manifiestamente con los principios descritos en el artículo 5 del GDPR.
    </p>
    <p>
      El RESPONSABLE garantiza que ha implementado políticas técnicas y organizativas apropiadas para aplicar las medidas de seguridad que establecen el GDPR y la LOPDGDD con el fin de proteger los derechos y libertades de los USUARIOS.
    </p>
    <p>
      Para más información sobre las garantías de privacidad, puedes dirigirte al RESPONSABLE a través de ONELAB Avda Costablanca 39, 03540 ALICANTE (Alacant). E-mail: <a href="mailto:INFO@ONELAB.ES">INFO@ONELAB.ES</a>
    </p>
  </div>
`

export default function Policy() {

    const params = useParams();

    const title = {
        legal: 'Aviso legal',
        privacy: 'Politica de privacidad'
    }

    const content = {
        legal: legal,
        privacy: privacy
    }

    

    return (
        
        <div id="policy-page">
            <Helmet>
                <title>Onelab | Política</title>
            </Helmet>
            <NavBar color={'black'}/> 

            <div id='policy-content'>
                <h1>{title[params.policy]}</h1>
                {Parser(content[params.policy])}
            </div>
        </div>
    );
}