import './NewPaymentDialog.css'
import React, { useState } from 'react';
import ApiManager from '../../ApiManager/ApiManager';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, FormControl, InputLabel, Select, MenuItem, InputAdornment, IconButton } from '@mui/material';
import { FaMagnifyingGlass } from "react-icons/fa6";
import moment from 'moment';
import 'moment/locale/es'; 
import Tooltip from '@mui/material/Tooltip';

import { FaSave } from "react-icons/fa";


export default function NewPaymentDialog({ open, setOpen, handleAddNewPayment, newPayment, setNewPayment }) {

    const [searchMessage, setSearchMessage] = useState(null)
    const concepts = ['Sesión de fisioterapia', 'Bono 5 sesiones fisioterapia', 'Bono 10 sesiones fisioterapia']
    const taxes = {
        'Sesión de fisioterapia': 0, 
        'Bono 5 sesiones fisioterapia': 10, 
        'Bono 10 sesiones fisioterapia': 0
    }
    const quantities = {
        'Sesión de fisioterapia': 40, 
        'Bono 5 sesiones fisioterapia': 180, 
        'Bono 10 sesiones fisioterapia': 320
    }
    const payment_methods = ['Tarjeta', 'Efectivo', 'Transferencia']
    

    const handleNewInvoiceChange = (e) => {
        const { name, value } = e.target;
        setNewPayment(prevPayment => ({ ...prevPayment, [name]: value }));
        if (name === 'concept') {
            setNewPayment(prevPayment => ({ ...prevPayment, 'tax': taxes[value] }))
            setNewPayment(prevPayment => ({ ...prevPayment, 'quantity': quantities[value] }))
        }
    };


    const handleSearchByNifClick = (e) => {
        e.preventDefault();
        if ((newPayment.nif !== '') | (newPayment.nif !== null)) {
            ApiManager
                .getPatientByNIF(newPayment.nif)
                .then((response) => {
                    if (response == null) {
                        setSearchMessage('Sin resultados')
                        setNewPayment({
                            id: '',
                            name: '',
                            date: '',
                            concept: '',
                            quantity: '',
                            tax: '',
                            payment_method: ''
                        })
                    } else {
                        setSearchMessage(null)
                        handleNewInvoiceChange({target: {name: 'name', value: response[0].name}})
                        handleNewInvoiceChange({target: {name: 'nif', value: response[0].nif}})
                    }
                })
                .catch((error) => {
                    console.error(error)
                })
        }
    }


    return (
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>Nuevo pago</DialogTitle>
            <DialogContent>
                <form onSubmit={handleSearchByNifClick}>
                    <TextField margin="dense" name="nif" label="NIF" fullWidth value={newPayment.nif} onChange={handleNewInvoiceChange} InputLabelProps={{shrink: true}}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleSearchByNifClick}>
                                        <FaMagnifyingGlass className='patient-directory-search-icon' />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}/>
                </form>
                <span><small>{searchMessage}</small></span>
                <TextField margin="dense" name="name" label="Nombre" fullWidth value={newPayment.name} onChange={handleNewInvoiceChange}  InputLabelProps={{shrink: true}}/>
                <TextField margin="dense" name="date" type='date' label='Fecha' fullWidth value={newPayment.date} onChange={handleNewInvoiceChange} InputLabelProps={{shrink: true}}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton 
                                    size="small" // Adjust size here
                                    sx={{ p: '4px' }} // Adjust padding if needed
                                    onClick={() => handleNewInvoiceChange({target: {name: 'date', value: moment().format('YYYY-MM-DD')}})}>
                                    <small>Hoy</small>
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <FormControl margin="dense" fullWidth>
                    <InputLabel id="concept-select-label">Concepto</InputLabel>
                    <Select
                        labelId="concept-select-label"
                        name="concept"
                        value={newPayment.concept}
                        onChange={handleNewInvoiceChange}
                        label="Concepto"
                    >
                        {concepts.map((concept) => (
                            <MenuItem key={concept} value={concept}>
                                {concept}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <TextField InputLabelProps={{ shrink: true }} margin="dense" name="tax" label="Impuesto" type="number" fullWidth value={newPayment.tax} onChange={handleNewInvoiceChange} />
                <TextField InputLabelProps={{ shrink: true }} margin="dense" name="quantity" label="Precio" type="number" fullWidth value={newPayment.quantity} onChange={handleNewInvoiceChange} />

                <FormControl margin="dense" fullWidth>
                    <InputLabel id="concept-select-label">Método pago</InputLabel>
                    <Select
                        labelId="method-select-label"
                        name="payment_method"
                        value={newPayment.payment_method}
                        onChange={handleNewInvoiceChange}
                        label="Método pago"
                    >
                        {payment_methods.map((m) => (
                            <MenuItem key={m} value={m}>
                                {m}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

            </DialogContent>
            <DialogActions>
                <Button id="new-payments-cancel-button" onClick={() => setOpen(false)} color="secondary">Cancelar</Button>
                <Tooltip arrow title="Guardar"><Button id="new-payments-confirm-button" onClick={handleAddNewPayment} color="primary"><FaSave /></Button></Tooltip>
            </DialogActions>
        </Dialog>
    );
}
