export const setToken = (token) => {
    localStorage.setItem('onelab-token', token)
}

export const deleteToken = () => {
    localStorage.clear();
}

export const getToken = () => {
    return localStorage.getItem('onelab-token');
}

export const getUserId = () => {
    return localStorage.getItem('onelab-token');
}