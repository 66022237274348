const ENDPOINTS = {

    // Reservations API
    GET_SLOTS: '/slot',
    GET_SLOT: '/slot/',
    CREATE_SLOT:'/slot/',
    CREATE_AUTOMATIC_SLOTS: '/automatic-slots',
    DELETE_SLOT:'/slot/',
    GET_RESERVATIONS: '/reservation',
    CREATE_RESERVATION: '/reservation',
    DELETE_RESERVATION: '/reservation/',
    PUT_RESERVATION: '/reservation/',
    GET_RESERVATION_BY_SLOT_ID: '/slot-reservation/',
    GET_PATIENT_NIF: '/patient/nif/',
    GET_PATIENT_HISTORY: '/reservation/patient/',
    CREATE_DEFAULT_SCHEDULE: '/schedule',
    GET_SCHEDULES: '/schedules',
    PUT_SCHEDULE: '/schedule/',
    POST_REMINDERS: '/reminder',
    GET_PAYMENTS_BY_ID: '/payment/',
    GET_PAYMENTS_BY_DATE: '/payment-date/',
    GET_PAYMENTS_BY_NIF: '/payment-nif/',
    POST_PAYMENT: '/payment',
    PUT_PAYMENT: '/payment/',
    POST_ONLINE_PAYMENT: '/payment/online/',


    // Auth API
    LOGIN: '/login',
    REGISTER: '/register',
    GET_PROFESSIONALS: {
        PHYSIOTHERAPIST: '/user/physiotherapist'
    },
    GET_STAFF: '/staff',
    PUT_STAFF: '/staff/',
    GET_PATIENTS: '/patient',
    GET_PATIENTS_BY_NAME: '/patient/name/',
    GET_PATIENTS_BY_NIF: '/patient/nif/',
    GET_PATIENTS_BY_TELEPHONE: '/patient/telephone/',
    CREATE_PATIENT: '/patient',
    MODIFY_PATIENT: '/patient/',

    // Blog API
    GET_ARTICLES_LIST: '/',
    GET_ARTICLE: '/article/',
    GET_ARTICLE_BY_KEYWORD: '/article/keyword/',
    POST_ARTICLE: '/article/',
    GET_ARTICLES_PAGINATED: '/page/',

    // Google reviews
    GET_REVIEWS: '/reviews'

    

}

export default ENDPOINTS;