import React, { useState } from 'react';
import './SlotModal.css';
import ApiManager from '../../ApiManager/ApiManager';
import moment from 'moment';
import 'moment/locale/es';
import { FaPlusCircle } from "react-icons/fa";
import { IoArrowBack } from "react-icons/io5";
import { Dialog, DialogTitle, DialogContent, DialogActions, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

export default function SlotModal({ professionals, event, onClose }) {
    const [selectedProfessional, setSelectedProfessional] = useState(null);

    moment.locale('es');

    const createSlot = () => {
        const form_data = {
            'start_time': moment(event.start).format('DD/MM/YYYY HH:mm'),
            'end_time': moment(event.end).format('DD/MM/YYYY HH:mm'),
            'user_id': selectedProfessional
        };
        ApiManager.createSlot(form_data)
            .then((response) => {
                window.location = '/dashboard';
            })
            .catch((error) => console.error(error));
    };

    return (
        <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle className="modal-header">
                <span className='modal-header-text'>Crear hueco</span> 
                <span className='modal-header-time'>{moment(event.start).format('ddd D, H:mm') + '-' + moment(event.end).format('LT')}</span>
            </DialogTitle>
            <DialogContent>
                <div id="slot-modal-body">
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="slot-modal-select-professional-label">Selecciona profesional</InputLabel>
                        <Select
                            labelId="slot-modal-select-professional-label"
                            value={selectedProfessional || ''}
                            onChange={(e) => setSelectedProfessional(e.target.value)}
                            // displayEmpty
                            InputLabelProps={{ shrink: true }}
                        >
                            <MenuItem value="" disabled>Selecciona profesional</MenuItem>
                            {professionals.map((p) => (
                                <MenuItem key={p.id} value={p.id}>{p.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            </DialogContent>
            <DialogActions id="slot-modal-actions">
                    <button className='slot-modal-form-button' id='slot-modal-form-button-back' onClick={onClose}><IoArrowBack />&nbsp;Atrás</button>
                    {selectedProfessional && <button className='slot-modal-form-button' id='slot-modal-form-button-update' onClick={createSlot}><FaPlusCircle />&nbsp;Crear</button>}
            </DialogActions>
        </Dialog>
    );
}
