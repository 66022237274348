import './DateForm.css'
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/es';


export default function DateForm({slots, setDateSlots, professional, setStep}) {

    moment.locale('es');

    const [dates, setDates] = useState([]);

    useEffect(() => {

        const today = moment();
        const endDate = moment().add(3, 'weeks').subtract(1, 'days');

        const datesArray = [];
        for (let d = moment(today); d <= endDate; d.add(1, 'day')) {

          datesArray.push({
            day: moment(d),
            slots: slots
                        .filter(slot => slot.user_id === professional.id)
                        .filter(slot => moment(slot.start_time).format('ddd D/M') === d.format('ddd D/M'))
                        .filter(slot => slot.status === 'FREE').length
          });
        }

        
        setDates(datesArray)


    }, [slots, professional.id])


    const handleSelect = (selectedDate) => {

        if (selectedDate.slots < 1) {
            return;
        }

        const selectedSlots = slots
                                .filter(slot =>slot.user_id === professional.id)
                                .filter(slot => moment(slot.start_time).format('ddd D/M') === moment(selectedDate.day).format('ddd D/M'))
        setDateSlots(selectedSlots)
        setStep('select-slot')


    }

    return (
        <>
            <div className="reservation-form-div" id='reservation-date-form'>
                {dates.map((d, i) => {
                    return (
                        
                        <div key={i} className={d['slots']>0 ? 'reservation-form-date-item':'reservation-form-date-item reservation-form-date-item-booked'}  onClick={() => handleSelect(d)}>
                            <div className='reservation-form-item-content-date'>
                                {  moment(d.day).date() === moment().date() ? 'Hoy'
                                    : moment(d.day).format('dddd D/M')
                                }
                            </div>
                            <div className={d['slots']>0 ? 'reservation-form-date-slots': 'reservation-form-date-slots reservation-form-date-slots-none'}>Huecos: {d['slots']}</div>
                        </div>
                    )
                })}
            </div>
            <div className="reservation-form-actions">
                <button className='reservation-form-button' id='reservation-form-button-cancel' onClick={() => setStep('select-professional')}>Atrás</button>
            </div>

        </>
    );
}