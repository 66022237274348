import './Payments.css';
import React, { useState } from 'react';
import ApiManager from '../../ApiManager/ApiManager';
import NewPaymentDialog from './NewPaymentDialog'
import EditPaymentDialog from './EditPaymentDialog';
import InvoiceDialog from './InvoiceDialog';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, TextField, InputAdornment, IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { CiCalendarDate, CiCircleQuestion } from "react-icons/ci";
import { HiIdentification } from "react-icons/hi2";
import { MdEuro, MdModeEdit } from "react-icons/md";
import { TbReceiptTax } from "react-icons/tb";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { PiCoins, PiInvoice } from "react-icons/pi";


import { IoMan } from "react-icons/io5";

import moment from 'moment';
import 'moment/locale/es'; 




export default function Payments() {
    moment.locale('es');

    const [payments, setPayments] = useState(null);
    const [selectedID, setSelectedID] = useState(null);
    const [selectedNIF, setSelectedNIF] = useState(null);
    const [selectedDate1, setSelectedDate1] = useState(moment().format('YYYY-MM-DD'));
    const [selectedDate2, setSelectedDate2] = useState(moment().format('YYYY-MM-DD'));
    const [openNewPaymentDialog, setOpenNewPaymentDialog] = useState(false);
    const [openEditPaymentDialog, setOpenEditPaymentDialog] = useState(false);
    const [openInvoiceDialog, setOpenInvoiceDialog] = useState(false);

    const [selectedPayment, setSelectedPayment] = useState({
        id: '',
        nif: '',
        name: '',
        date: '',
        concept: '',
        quantity: '',
        tax: '',
        payment_method: ''
    });
    const [newPayment, setNewPayment] = useState({
        id: '',
        nif: '',
        name: '',
        date: '',
        concept: '',
        quantity: '',
        tax: '',
        payment_method: ''
    });

    

    const handleAddNewPayment = () => {
        ApiManager
            .createPayment(newPayment)
            .then((response) => {
                setPayments([...payments, response]);
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                setNewPayment({ id: '', nif: '', name: '', date: '', concept: '', quantity: '', payment_method: ''});
                setOpenNewPaymentDialog(false);
            })
    };

    const handleEditPayment = (payment_id, params) => {
        ApiManager
            .editPayment(payment_id, params)
            .then((response) => {
                setPayments(prevPayments => 
                    prevPayments.map(payment => 
                        payment.id === payment_id ? response : payment
                    )
                );
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setNewPayment({ id: '', nif: '', name: '', date: '', concept: '', quantity: '', payment_method: '' });
                setOpenEditPaymentDialog(false);
            });
    };

    const handleInvoiceClick = (p) => {
        setSelectedPayment(p)
        setOpenInvoiceDialog(true)
    };

    const handleEditPaymentClick = (p) => {
        setSelectedPayment(p)
        setOpenEditPaymentDialog(true)
    }

    const handleSearchDates = (e) => {
        e.preventDefault();
        setSelectedNIF('')
        setSelectedID('')
        if ((selectedDate1 !== null) && (selectedDate2 !== null) && (selectedDate1 !== '') && (selectedDate2 !== '')) {
            
            ApiManager
                .getPaymentsByDates(selectedDate1, selectedDate2)
                .then((response) => {
                    setPayments(response)
                })
                .catch((error) => {
                    console.error(error)
                })
        }
    }

    const handleSearchNif = (e) => {
        e.preventDefault();
        setSelectedDate1('')
        setSelectedDate2('')
        setSelectedID('')
        if ((selectedNIF !== null) && (selectedNIF !== '')){
            ApiManager
                .getPaymentsByNIF(selectedNIF)
                .then((response) => {
                    setPayments(response)
                })
                .catch((error) => {
                    console.error(error)
                })
        }
    }

    const handleSearchID = (e) => {
        e.preventDefault();
        setSelectedDate1('')
        setSelectedDate2('')
        setSelectedNIF('')
        if ((selectedID !== null) && (selectedID !== '')){
            ApiManager
                .getPaymentsByID(selectedID)
                .then((response) => {
                    setPayments(response)
                })
                .catch((error) => {
                    console.error(error)
                })
        }
    }

    return ( 
        <>
        <div id="payments-wrapper">
            <div id="payments-directory-page-banner">
                <h1 id="payments-directory-banner-title">Pagos</h1>
                <button id='payments-new-button' onClick={() => setOpenNewPaymentDialog(true)}>+ Nuevo</button>
            </div>

            <div id="payments-directory-search-wrapper">
                <form  className='payments-directory-search-block' onSubmit={handleSearchID}>
                    <TextField className='payment-search-input' size="small" margin="dense" name="nif" label="ID" value={selectedID || ''}  onChange={(e) => setSelectedID(e.target.value)} 
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleSearchID}>
                                        <FaMagnifyingGlass className='patient-directory-search-icon' />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        InputLabelProps={{shrink: true}}
                    />
                </form>
                <form  className='payments-directory-search-block' onSubmit={handleSearchNif}>
                    <TextField 
                        className='payment-search-input' 
                        size="small"  
                        margin="dense" 
                        name="nif" 
                        label="NIF" 
                        value={selectedNIF || ''}  
                        onChange={(e) => setSelectedNIF(e.target.value)} 
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleSearchNif}>
                                        <FaMagnifyingGlass className='patient-directory-search-icon' />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        InputLabelProps={{shrink: true}}
                    />
                </form>
                <form  className='payments-directory-search-block' onSubmit={handleSearchDates}>
                    
                    <TextField type='date' className='payment-search-input' size="small"  margin="dense" name="date1" label="Desde" fullWidth value={selectedDate1 || ''} onChange={(e) => setSelectedDate1(e.target.value)} InputLabelProps={{shrink: true}}/>
                    <TextField type='date' className='payment-search-input' size="small"  margin="dense" name="date2" label="Hasta" fullWidth value={selectedDate2 || ''} onChange={(e) => setSelectedDate2(e.target.value)} InputLabelProps={{shrink: true}}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleSearchDates}>
                                        <FaMagnifyingGlass className='patient-directory-search-icon' />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </form>
            </div>

            <div id='payments-directory-table'>
                <TableContainer component={Paper}>
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell className='payments-table-header' align="center">
                                    <Tooltip title="ID" arrow>
                                        <span>#</span>
                                    </Tooltip>
                                </TableCell>
                                <TableCell className='payments-table-header' align="center">
                                    <Tooltip title="NIF" arrow>
                                        <span><HiIdentification /></span>
                                    </Tooltip>
                                </TableCell>
                                <TableCell className='payments-table-header' align="center">
                                    <Tooltip title="Nombre" arrow>
                                        <span><IoMan /></span>
                                    </Tooltip>
                                </TableCell>
                                <TableCell className='payments-table-header' align="center">
                                    <Tooltip title="Fecha" arrow>
                                        <span><CiCalendarDate /></span>
                                    </Tooltip>
                                </TableCell>
                                <TableCell className='payments-table-header' align="center">
                                    <Tooltip title="Concepto" arrow>
                                        <span><CiCircleQuestion /></span>
                                    </Tooltip>
                                </TableCell>
                                <TableCell className='payments-table-header' align="center">
                                    <Tooltip title="Impuesto" arrow>
                                        <span><TbReceiptTax /></span>
                                    </Tooltip>
                                </TableCell>
                                <TableCell className='payments-table-header' align="center">
                                    <Tooltip title="Cantidad" arrow>
                                        <span><MdEuro /></span>
                                    </Tooltip>
                                </TableCell>
                                <TableCell className='payments-table-header' align="center">
                                    <Tooltip title="Método de pago" arrow>
                                        <span><PiCoins /></span>
                                    </Tooltip>
                                </TableCell>
                                <TableCell className='payments-table-header' align="left"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {payments && payments.map((p) => (
                            <React.Fragment key={p.id}>
                                <TableRow className="table-row" sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell align="left">{p.id}</TableCell>
                                    <TableCell align="left">{p.nif}</TableCell>
                                    <TableCell align="left">{p.name}</TableCell>
                                    <TableCell align="left">{moment(p.date, 'YYYY/MM/DD').format('DD/MM/YYYY')}</TableCell>
                                    <TableCell align="left">{p.concept}</TableCell>
                                    <TableCell align="center">{p.tax}%</TableCell>
                                    <TableCell align="center">{parseFloat(p.quantity).toFixed(2)}</TableCell>
                                    <TableCell align="left">{p.payment_method}</TableCell>
                                    <TableCell align="center" className='table-actions-cell'>
                                        <Tooltip arrow title="Factura"><button className='patient-history-button' onClick={() => handleInvoiceClick(p)}><PiInvoice /></button></Tooltip>
                                        <Tooltip arrow title="Editar"><button className='patient-history-button'  onClick={() => handleEditPaymentClick(p)}><MdModeEdit /></button></Tooltip>
                                    </TableCell>
                                </TableRow>
                                
                            </React.Fragment>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            
            <NewPaymentDialog  newPayment={newPayment} setNewPayment={setNewPayment} open={openNewPaymentDialog} setOpen={setOpenNewPaymentDialog} handleAddNewPayment={handleAddNewPayment} />
            <EditPaymentDialog payment={selectedPayment} setPayment={setSelectedPayment} open={openEditPaymentDialog} setOpen={setOpenEditPaymentDialog} handleEditPayment={handleEditPayment} />
            <InvoiceDialog payment={selectedPayment} open={openInvoiceDialog} setOpen={setOpenInvoiceDialog} />
                            
        </div>
        
        </>
    );
}
