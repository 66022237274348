import './Navbar.css'
import React, { useState, useEffect } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { logoPath } from '../../media/logos';
import { MdOutlineArticle } from "react-icons/md";
import { IoMdLogIn } from "react-icons/io";
// import { IoPricetagOutline } from "react-icons/io5";
import { IoCalendarOutline } from "react-icons/io5";

import { getToken } from '../../auth/Token';

import { calculateTimeLeft } from "../Countdown/Countdown"


export default function NavBar() {

    const token = getToken();

    const [ scroll, setScroll ] = useState(0);
    const [ showNavbar, setShowNavbar ] = useState(true);


    

    useEffect(() => {

        const handleScroll = () => {
            const position = document.documentElement.scrollTop || document.body.scrollTop;
    
            if (position > scroll) {
                setShowNavbar(false)
            } else {
                setShowNavbar(true)
            }
    
            setScroll(position)
        };


        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, [scroll]);

    if ( calculateTimeLeft().days > 30){
        return (
            <Navbar collapseOnSelect expand="lg" bg="none" id='navbar' className={showNavbar ? 'navbar-show': 'navbar-hide'}>
            <Navbar.Brand href="/" id="navbar-logo">
                <img id="navbar-logo-img" src={logoPath['black']} alt="onelab-logo" height="100" />
            </Navbar.Brand>
        </Navbar>
        )
    }

    return (
        <Navbar collapseOnSelect expand="lg" bg="none" id='navbar' className={showNavbar ? 'navbar-show': 'navbar-hide'}>
            <Navbar.Brand href="/" id="navbar-logo">
                <img id="navbar-logo-img" src={logoPath['black']} alt="onelab-logo" height="100" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbar-menu" className="border-0 custom-toggler" id='navbar-toggle-btn'/>

            <Navbar.Collapse id="navbar-menu">
                <Nav  id="navbar-menu-list" className='navbar-menu-list'>
                    <Nav.Link className='nav-link'  href="/reservation">Reserva&nbsp;<IoCalendarOutline /></Nav.Link>
                    {/* <Nav.Link className='nav-link' href="#">Tarifas&nbsp;<IoPricetagOutline /></Nav.Link> */}
                    <Nav.Link className='nav-link' href="/blog/1">Blog&nbsp;<MdOutlineArticle /></Nav.Link>
                    <Nav.Link className='nav-link' href={token ? '/dashboard' : '/login'}>Login&nbsp;<IoMdLogIn /></Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}