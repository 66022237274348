import './Success.css'
import React, { useState, useEffect} from 'react';
import { useNavigate } from 'react-router';
import {Helmet} from "react-helmet";
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import check_svg from '../../media/check.svg'
import NavBar from '../../components/Navbar/Navbar';

import moment from 'moment';
import 'moment/locale/es';


import ApiManager from '../../ApiManager/ApiManager'


export default function Success() {

    const navigate = useNavigate();

    moment.locale('es');

    const params = useParams();
    const [slot, setSlot] = useState();

    const [searchParams] = useSearchParams();
    const paid = searchParams.get('paid');

    useEffect(()=> {
        ApiManager
            .getSlot(params.slot_id)
            .then((response => {setSlot(response)}))
            .catch((error => {console.error(error)}))
    }, [params.slot_id])


    const handlePayOnline = () => {
        navigate('/pay-online/' + slot.id)
    }

    useEffect(() => {

        if (!paid) {
            ApiManager
                .confirmOnlinePayment(params.slot_id)
                .then((response => {console.log(response)}))
                .catch((error => {console.error(error)}))
        }

    }, [params.slot_id, paid])

    return slot && (
        <>
        <Helmet>
            <title>Success | Onelab</title>
        </Helmet>
        <NavBar />
        <div id="success-div">
            <div id='success-message'>
                <h3>
                    {paid ?
                        'Pago confirmado'
                        :
                        'Reserva confirmada'
                    }
                </h3>

                {!paid && 
                    <>
                        <h5>El {moment(slot.start_time).format('dddd D/M')}, de {moment(slot.start_time).format('HH:mm')} a {moment(slot.end_time).format('HH:mm')}</h5>
                    </>
                }
                        <img  alt="check-svg" src={check_svg}></img>
                {!paid && 
                    <>
                        <hr></hr>
                    
                        * Recibirás un email con la confirmación (revisa spam)


                        
                        <button id='pay-message' onClick={handlePayOnline}>
                            Pagar con 5 % de descuento
                        </button>
                    </>
                }
            
            
            </div>

                

        </div>
        </>
    );
}