import './Home.css'
import {Helmet} from "react-helmet";
import React from 'react';

import NavBar from '../../components/Navbar/Navbar';
import HomeBanner from '../../components/HomeBanner/HomeBanner';
import Contact from '../../components/Contact/Contact';
import ClinicGallery from '../../components/ClinicGallery/ClinicGallery'
import Services from '../../components/Services/Services'
import ProfessionalsList from '../../components/ProfessionalsList/ProfessionalsList';
import Reviews from '../../components/Reviews/Reviews';
import Footer from '../../components/Footer/Footer';



export default function Home() {

    return (
        
        <div id="home-page">
            <Helmet>
                <title>{/* Clínica Fisioterapia y Readaptación Deportiva en Alicante -  */}ONELAB</title>
                {/* <meta name="description" content="ONELAB es una Clínica de Fisioterapia y Readaptación Deportiva en Alicante."/> */}
                <meta name="keywords" content=""/>
                <link rel="canonical" href="https://onelab.es"/>
                <meta name="robots" content="index, follow"/>
            </Helmet>
            <NavBar /> 
            <HomeBanner />
            <Contact />
            <ClinicGallery />
            <Services />
            <ProfessionalsList id="equipo"/>
            <Reviews />
            <Footer />
        </div>
    );
}
