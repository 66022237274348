import './Countdown.css'
import React from 'react';
import { logoPath } from '../../media/logos';

import { Helmet } from "react-helmet";


export const calculateTimeLeft = () => {
    const targetDate = new Date('2025-01-01T00:00:00');
    const now = new Date();
    const difference = targetDate - now;

    let timeLeft = {};

    if (difference > 0) {
        timeLeft = {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60),
        };
    }

    return timeLeft;
};

const Countdown = () => {


    return (
        <div id='countdown'>
            <Helmet>
                {/* <title>Onelab | Fisioterapia y readaptación deportiva en playa de San Juan</title> */}
                {/* <meta name="description" content="Fisioterapia y readaptación en playa de San Juan"/> */}
                <meta name="keywords" content="clínica fisioterapia, fisioterapia, cabo de las huertas, San Juan Playa, Playa de San Juan, entrenamiento, readaptación deportiva"/>
                <link rel="canonical" href="https://onelab.es"/>
                <meta name="robots" content="index, follow"/>
            </Helmet>
            <div id='logo-wrapper'>
                <img src={[logoPath['white']]} alt='logo'></img>
            </div>

            <a href="/blog">Ver Blog</a>
            
        </div>
    );
};

export default Countdown;