import './Loader.css'


export default function Loader({color='grey'}) {

    return (
        <div id='loading-div'>
            <span className={`loader loader-${color}`}></span>
        </div>
    );
}