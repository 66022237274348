import './SlotForm.css'
import moment from 'moment';
import 'moment/locale/es';
import React, { useEffect } from 'react';


export default function SlotForm({dateSlots, setSlot, setStep}) {

    useEffect(() => {
        setSlot(null)
    }, [setSlot])

    moment.locale('es');

    const handleSelect = (slot) => {
        if (slot.status !== 'FREE') {
            return;
        }

        setSlot(slot)
        setStep('confirmation')
    }

    return (
        <>
            <div className="reservation-form-div" id='reservation-slot-form'>
                {dateSlots.map((s, i) => {
                    return (
                        <div key={i} className={s.status==='FREE'? 'reservation-form-slot-item':'reservation-form-slot-item reservation-form-slot-item-booked'}  onClick={() => handleSelect(s)}>
                            {moment(s.start_time).format('H:mm') + ' - ' + moment(s.end_time).format('H:mm')}
                        </div>
                    )
                })}
            </div>
            <div className="reservation-form-actions">
                    <button className='reservation-form-button' id='reservation-form-button-cancel' onClick={() => setStep('select-date')}>Atrás</button>
            </div>
        </>

    );
}