import ENDPOINTS from "./Endpoints";
import ApiMethods from "./ApiMethods";
import AWS from "aws-sdk";


const isLocalEnv = process.env.REACT_APP_ENV === 'development';

export const PAGE_URL  = isLocalEnv ? 'http://localhost:3000' : 'https://onelab.es';
export const AUTH_BASE_URL = isLocalEnv ? 'http://localhost:8080' : 'https://auth.onelab.es';
export const BLOG_BASE_URL = isLocalEnv ? 'http://localhost:8081' : 'https://blog.onelab.es';
export const RESERVATIONS_BASE_URL = isLocalEnv ? 'http://localhost:8082' : 'https://reservations.onelab.es';

const S3_BUCKET ='onelab-media-files';
const REGION ='eu-south-2';
const AWS_ACCESS_KEY ='AKIATOVCS3I6WBTIA6MU';
const AWS_SECRET_ACCESS_KEY ='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';


const ApiManager = {

    createDefaultSchedule: (params) => {
        const url = RESERVATIONS_BASE_URL + ENDPOINTS.CREATE_DEFAULT_SCHEDULE;
        return ApiMethods.post(url, params)
    },

    getSchedules: () => {
        const url = RESERVATIONS_BASE_URL + ENDPOINTS.GET_SCHEDULES;
        return ApiMethods.get(url)
    },

    putSchedule: (schedule_id, params) => {
        const url = RESERVATIONS_BASE_URL + ENDPOINTS.PUT_SCHEDULE + schedule_id;
        return ApiMethods.put(url, params)
    },

    getSlots: () => {
        const url = RESERVATIONS_BASE_URL + ENDPOINTS.GET_SLOTS;
        return ApiMethods.get(url)
    },

    getSlot: (slot_id) => {
        const url = RESERVATIONS_BASE_URL + ENDPOINTS.GET_SLOT + slot_id;
        return ApiMethods.get(url)
    },

    deleteSlot: (slot_id) => {
        const url = RESERVATIONS_BASE_URL + ENDPOINTS.DELETE_SLOT + slot_id;
        return ApiMethods.delete(url)
    },

    createSlot: (params) => {
        const url = RESERVATIONS_BASE_URL + ENDPOINTS.CREATE_SLOT;
        return ApiMethods.post(url, params);
    },

    createAutomaticSlots: (params) => {
        const url = RESERVATIONS_BASE_URL + ENDPOINTS.CREATE_AUTOMATIC_SLOTS;
        return ApiMethods.post(url, params);
    },

    getReservations: () => {
        const url = RESERVATIONS_BASE_URL + ENDPOINTS.GET_RESERVATIONS;
        return ApiMethods.get(url)
    },

    getReservation: (slot_id) => {
        const url = RESERVATIONS_BASE_URL + ENDPOINTS.GET_RESERVATION_BY_SLOT_ID + slot_id;
        return ApiMethods.get(url)
    },

    getPatientByNIF: (nif) => {
        const url = RESERVATIONS_BASE_URL + ENDPOINTS.GET_PATIENT_NIF + nif;
        return ApiMethods.get(url)
    },

    getPatientHistory: (patient_id) => {
        const url = RESERVATIONS_BASE_URL + ENDPOINTS.GET_PATIENT_HISTORY + patient_id;
        return ApiMethods.get(url)
    },

    createReservation: (params) => {
        const url = RESERVATIONS_BASE_URL + ENDPOINTS.CREATE_RESERVATION;
        return ApiMethods.post(url, params)
    },

    deleteReservation: (reservation_id) => {
        const url = RESERVATIONS_BASE_URL + ENDPOINTS.DELETE_RESERVATION + reservation_id;
        return ApiMethods.delete(url)
    },

    saveReservation: (reservation_id, params) => {
        const url = RESERVATIONS_BASE_URL + ENDPOINTS.PUT_RESERVATION + reservation_id;
        return ApiMethods.put(url, params)
    },

    sendReminders: () => {
        const url = RESERVATIONS_BASE_URL + ENDPOINTS.POST_REMINDERS;
        return ApiMethods.post(url)
    },

    getPaymentsByID: (id) => {
        const url = RESERVATIONS_BASE_URL + ENDPOINTS.GET_PAYMENTS_BY_ID + id;
        return ApiMethods.get(url)
    },

    getPaymentsByDate: (date) => {
        const url = RESERVATIONS_BASE_URL + ENDPOINTS.GET_PAYMENTS_BY_DATE + date;
        return ApiMethods.get(url)
    },

    getPaymentsByDates: (date1, date2) => {
        const url = RESERVATIONS_BASE_URL + ENDPOINTS.GET_PAYMENTS_BY_DATE + date1 + '/' + date2;
        return ApiMethods.get(url)
    },

    getPaymentsByNIF: (nif) => {
        const url = RESERVATIONS_BASE_URL + ENDPOINTS.GET_PAYMENTS_BY_NIF + nif;
        return ApiMethods.get(url)
    },

    createPayment: (params) => {
        const url = RESERVATIONS_BASE_URL + ENDPOINTS.POST_PAYMENT;
        return ApiMethods.post(url, params);
    },


    editPayment: (payment_id, params) => {
        const url = RESERVATIONS_BASE_URL + ENDPOINTS.PUT_PAYMENT + payment_id;
        return ApiMethods.put(url, params);
    },

    confirmOnlinePayment: (slot_id) => {
        const url = RESERVATIONS_BASE_URL + ENDPOINTS.POST_ONLINE_PAYMENT + slot_id;
        return ApiMethods.post(url);
    },





    login : (params) =>  {
        const url = AUTH_BASE_URL + ENDPOINTS.LOGIN;
        return ApiMethods.post(url, params)
    },

    register : (params) =>  {
        const url = AUTH_BASE_URL + ENDPOINTS.REGISTER;
        return ApiMethods.post(url, params)
    },

    getPhysiotherapists: () => {
        const url = AUTH_BASE_URL + ENDPOINTS.GET_PROFESSIONALS.PHYSIOTHERAPIST;
        return ApiMethods.get(url)
    },

    getStaff: () => {
        const url = AUTH_BASE_URL + ENDPOINTS.GET_STAFF;
        return ApiMethods.get(url)
    },

    saveStaff: (user_id, params) => {
        const url = AUTH_BASE_URL + ENDPOINTS.PUT_STAFF + user_id;
        return ApiMethods.put(url, params)
    },

    getPatients: () => {
        const url = RESERVATIONS_BASE_URL + ENDPOINTS.GET_PATIENTS;
        return ApiMethods.get(url)
    },

    getPatientsByName: (name) => {
        const url = RESERVATIONS_BASE_URL + ENDPOINTS.GET_PATIENTS_BY_NAME + name;
        return ApiMethods.get(url)
    },

    getPatientsByNIF: (nif) => {
        const url = RESERVATIONS_BASE_URL + ENDPOINTS.GET_PATIENTS_BY_NIF + nif;
        return ApiMethods.get(url)
    },

    getPatientsByTelephone: (telephone) => {
        const url = RESERVATIONS_BASE_URL + ENDPOINTS.GET_PATIENTS_BY_TELEPHONE + telephone;
        return ApiMethods.get(url)
    },

    createPatient: (params) => {
        const url = RESERVATIONS_BASE_URL + ENDPOINTS.CREATE_PATIENT;
        return ApiMethods.post(url, params)
    },

    modifyPatient: (patient_id, params) => {
        const url = RESERVATIONS_BASE_URL + ENDPOINTS.MODIFY_PATIENT + patient_id;
        return ApiMethods.put(url, params)
    },

    getArticlesList: () => {
        const url = BLOG_BASE_URL + ENDPOINTS.GET_ARTICLES_LIST;
        return ApiMethods.get(url)
    },

    getArticlesPaginated: (page) => {
        const url = BLOG_BASE_URL + ENDPOINTS.GET_ARTICLES_PAGINATED + page;
        return ApiMethods.get(url)
    },

    getArticle: (slug) => {
        const url = BLOG_BASE_URL + ENDPOINTS.GET_ARTICLE + slug;
        return ApiMethods.get(url)
    },

    getArticleByKeyword: (keyword) => {
        const url = BLOG_BASE_URL + ENDPOINTS.GET_ARTICLE_BY_KEYWORD + keyword;
        return ApiMethods.get(url)
    },

    postArticle: (params) => {
        const url = BLOG_BASE_URL + ENDPOINTS.POST_ARTICLE
        return ApiMethods.post(url, params)
    },

    uploadArticleImage: async (file, filename) => {
        AWS.config.update({accessKeyId: AWS_ACCESS_KEY, secretAccessKey: AWS_SECRET_ACCESS_KEY});
        const s3 = new AWS.S3({ 
            params: { Bucket: S3_BUCKET },
            region: REGION,
        });
        const params = {
            Bucket: S3_BUCKET,
            Key: 'blog/' + filename,
            Body: file,
        };
        var upload = s3
            .putObject(params)
            .on("httpUploadProgress", (evt) => {
                // console.log(Math.round((evt.loaded * 100) / evt.total))
            })
            .promise();
        await upload.then((err, data) => {
            // console.log(data)
        });
    },

    uploadStaffImage: async (file, filename) => {
        AWS.config.update({accessKeyId: AWS_ACCESS_KEY, secretAccessKey: AWS_SECRET_ACCESS_KEY});
        const s3 = new AWS.S3({ 
            params: { Bucket: S3_BUCKET },
            region: REGION,
        });
        const params = {
            Bucket: S3_BUCKET,
            Key: 'staff/' + filename,
            Body: file,
        };
        var upload = s3
            .putObject(params)
            .on("httpUploadProgress", (evt) => {
                // console.log(Math.round((evt.loaded * 100) / evt.total))
            })
            .promise();
        await upload.then((err, data) => {
            // console.log(data)
        });
    },

    getReviews: async () => {
        const url = RESERVATIONS_BASE_URL + ENDPOINTS.GET_REVIEWS
        return ApiMethods.get(url)
    }


};

export default ApiManager


