
import axios from "axios";

import { getToken } from "../auth/Token";


const token = getToken();

const config = {
    headers: { 
        Authorization: `Bearer ${token}` ,
        'Content-Type': 'application/json'
    }
}

const ApiMethods = {

    get: (url) => {
        return axios.get(url, config)
            .then((response => {
                return response.data
            })
        );
    },

    post: (url, params) => {
        return axios.post(url, params, config)
            .then((response => {
                return response.data
            })
        );
    },

    put: (url, params) => {
        return axios.put(url, params, config)
            .then((response => {
                return response.data
            })
        );
    },

    delete: (url, params) => {
        return axios.delete(url, config)
            .then((response => {
                return response.data
            })
        );
    }
};

export default ApiMethods;