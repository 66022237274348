import './ClinicGallery.css';
import React, { useEffect, useRef } from 'react';

const ClinicGallery = () => {
    const images = [
        'https://onelab-media-files.s3.eu-south-2.amazonaws.com/clinic_gallery/3.webp',
        'https://onelab-media-files.s3.eu-south-2.amazonaws.com/clinic_gallery/4.webp',
        'https://onelab-media-files.s3.eu-south-2.amazonaws.com/clinic_gallery/5.webp',
        'https://onelab-media-files.s3.eu-south-2.amazonaws.com/clinic_gallery/4.webp',
        'https://onelab-media-files.s3.eu-south-2.amazonaws.com/clinic_gallery/5.webp',
        'https://onelab-media-files.s3.eu-south-2.amazonaws.com/clinic_gallery/4.webp',
        'https://onelab-media-files.s3.eu-south-2.amazonaws.com/clinic_gallery/5.webp',
        'https://onelab-media-files.s3.eu-south-2.amazonaws.com/clinic_gallery/5.webp',
        'https://onelab-media-files.s3.eu-south-2.amazonaws.com/clinic_gallery/4.webp',
        'https://onelab-media-files.s3.eu-south-2.amazonaws.com/clinic_gallery/5.webp',
        'https://onelab-media-files.s3.eu-south-2.amazonaws.com/clinic_gallery/4.webp',
        'https://onelab-media-files.s3.eu-south-2.amazonaws.com/clinic_gallery/5.webp',
    ];

    const galleryRef = useRef(null);

    useEffect(() => {
        const scrollGallery = () => {
            if (galleryRef.current) {
                galleryRef.current.scrollLeft += 1;
            }
        };

        const intervalId = setInterval(scrollGallery, 10);

        return () => clearInterval(intervalId);
    }, []);


    return (
        <div id='home-sction-gallery'>
            <h2 className="home-section-title" id='home-clinic-title'>La clínica</h2>
            <div id='home-clinic-gallery' ref={galleryRef}>
                
                {images.map((image, index) => (
                    <img
                        key={index}
                        src={image}
                        className='clinic-gallery-image'
                        alt={`clinic-${index}`}
                    />
                ))}
            </div>
        </div>
    );
};

export default ClinicGallery;
