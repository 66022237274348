import './ArticleCard.css'
import moment from 'moment';
import 'moment/locale/es';



export default function ArticleCard({article}) {

  moment.locale('es');

  const handleClick = () => {
    window.location = '/blog/article/' + article.slug
  }

  return (
    <div className='article-card' onClick={handleClick}>
      <div className='article-card-img-wrapper'>
        <img className='article-card-img' src={article.image} alt={article.title}></img>
      </div>
      <div className='article-card-description-wrapper'>
        <p className='article-card-date'>{moment(article.created_at).format('D MMM YYYY')}</p>
        <h2 className='article-card-title'>{article.title}</h2>
        {/* <h4 className='article-card-subtitle'>{article.subtitle}</h4> */}
      </div>
    </div>
  );
}